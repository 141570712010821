import React, { useEffect, useMemo, useState } from 'react';
import { Divider } from 'antd';
import { Task } from '../../services/openapi.types.generated';
import { WithExpertPanel } from '../../components/Layout/withExperModal.hoc';
import { getCurrentMilestone } from '../../components/Milestones/utils';
import { ActionTaskSection } from '../../components/Project/ActionTaskSection';
import { MilestoneHeader } from '../../components/Project/MilestoneHeader';
import { WithSubject, WithSubjectProps } from '../../components/Layout/withSubject.hoc';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../services/appInsights';
import { WithLayout } from '../../components/Layout/withLayout.hoc';

const ActionTaskScreen = WithExpertPanel(ActionTaskSection);

type ProjectPageProps = WithSubjectProps;

export const Component: React.FC<ProjectPageProps> = ({ subject, setSubject }: ProjectPageProps) => {
  if (!subject) return <></>;

  const { milestones, id, pathway, applicationId } = subject;
  const inProgressMilestone = useMemo(() => getCurrentMilestone(milestones || []), [milestones]);

  const [actionTasks, setActionTasks] = useState<Task[]>([]);

  useEffect(() => {
    if (inProgressMilestone && inProgressMilestone.tasks) {
      const newActionTasks = milestones.filter(({ tasks }) => tasks?.length).flatMap(({ tasks }) => tasks as Task[]);

      setActionTasks(newActionTasks);
    }
  }, [inProgressMilestone, setActionTasks]);

  return (
    <div id="continue-to-payment">
      <>
        <MilestoneHeader
          status={`Project ${applicationId}`}
          pathway={pathway?.name}
          description={pathway?.description}
          reason={pathway?.reason}
          subject={subject}
        />

        <span style={{ margin: 5 }}>
          <Divider />
        </span>
      </>
      <ActionTaskScreen
        actionTasks={actionTasks}
        id={id}
        subject={subject}
        currentMilestone={inProgressMilestone}
        setProject={setSubject}
      />
    </div>
  );
};

export const ProjectPage = withAITracking(reactPlugin, WithSubject(WithLayout(Component)));
