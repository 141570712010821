import LinearProgress from '@material/react-linear-progress';
import '@material/react-linear-progress/dist/linear-progress.css';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { pl, px } from 'styled-components-spacing';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import { DocumentRequest, DocumentResponse } from '../../../../services/openapi.types.generated';
import { ReactComponent as CrossIcon } from '../../../../svg/cross.svg';
import { ReactComponent as FolderIcon } from '../../../../svg/folder-circle.svg';
import { colorBlue, colorRed, gray } from '../../../../theme.selectors';
import { formatSize } from '../../../helpers';
import { FieldError } from '../../FieldError';

type FileProps = {
  file: File;
  recaptcha: string | null;
  onCancel: () => void;
  onComplete: (successful: boolean, id?: string) => Promise<void>;
};

type ContainerProps = {
  isLoading: boolean;
  isError: boolean;
};

const BoxContainer = styled.div<ContainerProps>`
  border: 1px solid ${gray};
  border-radius: 4px;
  background-color: ${({ isError, theme }): string => (isError ? theme.colours.red : theme.colours.extraLightGray)};
  display: flex;
  align-items: center;
  padding: 16px;
  opacity: ${({ isLoading }): number => (isLoading ? 0.5 : 1)};

  svg {
    flex: 0 0 auto;
  }
`;

const StyledDiv = styled.div`
  font-size: 18px;
  text-decoration: underline;
  ${px(4)};
  flex: 1 1 0%;
  display: flex;
  min-width: 0;
`;

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileSize = styled.div`
  word-break: keep-all;
  ${pl(2)};
`;

const StyledBar = styled(LinearProgress)`
  .mdc-linear-progress__bar-inner {
    background-color: ${colorBlue};
  }
`;

const StyledErrorBar = styled(LinearProgress)`
  .mdc-linear-progress__bar-inner {
    background-color: ${colorRed};
  }
`;

const Container = styled.div``;

const StyledCross = styled(CrossIcon)`
  cursor: pointer;
`;

export const FileBox = ({ file, onCancel, onComplete, recaptcha }: FileProps): JSX.Element => {
  const { size, type, name } = file;

  const [status, setStatus] = useState<'error' | 'complete' | 'uploading'>('uploading');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [awaitingProjectUpdate, setAwaitingProjectUpdate] = useState(false);

  const [{ data: document, error: documentError }, createDocumentRequest] = useApiRequest<DocumentResponse>(
    {
      url: 'documents',
      method: 'post',
    },
    {
      manual: true,
      authRedirect: false,
    },
  );

  const createDocument = useDebouncedCallback(async (document: DocumentRequest) => {
    try {
      await createDocumentRequest({
        data: document,
      });
    } catch (e) {}
  }, 300);

  useEffect(() => {
    createDocument({ size, name, type, recaptcha });
  }, []);

  const uploadDocument = async (file: File, document: DocumentResponse) => {
    try {
      setStatus('uploading');
      const { url, uploadToken } = document;
      await axios.put(`${url}?${uploadToken}`, file, {
        headers: { 'x-ms-blob-type': 'BlockBlob' },
        onUploadProgress: handleUploadProgress,
      });

      setStatus('complete');
      setProgress(100);
    } catch (e) {
      setStatus('error');
      setErrorMessage('Failed to upload file');
      console.error(e);
    }
  };

  const handleUploadProgress = useCallback(
    (progressEvent: ProgressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgress(percentCompleted);
    },
    [setProgress],
  );

  useEffect(() => {
    if (document) {
      uploadDocument(file, document);
    }
  }, [document]);

  useEffect(() => {
    if (documentError) {
      setErrorMessage('Failed to create new document');
      setStatus('error');
    }
  }, [documentError]);

  useEffect(() => {
    if (documentError) setStatus('error');
  }, [documentError]);

  useEffect(() => {
    const handleUploadComplete = async () => {
      if (status === 'complete') {
        setAwaitingProjectUpdate(true);
        await onComplete(true, document?.id);
        setAwaitingProjectUpdate(false);
      }
      if (status === 'error') {
        onComplete(false, document?.id);
      }
    };
    handleUploadComplete();
  }, [status]);
  const handleCancel = () => {
    onCancel();
  };
  return (
    <Container>
      <BoxContainer
        isLoading={status === 'uploading' || (status === 'complete' && !!awaitingProjectUpdate)}
        isError={status === 'error'}
      >
        <FolderIcon />
        <StyledDiv>
          <FileName>{name}</FileName>
          <FileSize>({formatSize(size)})</FileSize>
        </StyledDiv>
        <StyledCross onClick={handleCancel} />
      </BoxContainer>
      {((progress < 100 && status !== 'error') || ('complete' && awaitingProjectUpdate)) && (
        <StyledBar
          buffer={1}
          bufferingDots={false}
          progress={progress / 100}
          indeterminate={status === 'complete' && awaitingProjectUpdate}
        />
      )}
      {status === 'error' && <StyledErrorBar bufferingDots={false} progress={1} />}
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
    </Container>
  );
};
