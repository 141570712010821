import { useEffect } from 'react';

/**
 * Used to send the user to the top of the page.
 */
export const useScrollToTop = (): void => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};
