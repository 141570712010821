import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { pl } from 'styled-components-spacing';
import { PhoneFabButton } from '../Button';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  flex: 1 1 0%;
  min-width: 0;
`;

const Right = styled.div`
  display: none;

  ${breakpoint('desktop')`
    display: block;
    flex: 0 0 auto;
    ${pl(7)};
  `}
`;

const FloatingContactPanel = styled.div`
  display: block;

  ${breakpoint('desktop')`
    display: none;
  `}
`;

const PositionedContactPanel = styled.div`
  top: ${({ theme }) => theme.spacing[7]};
  position: sticky;
`;

type TwoColumnsProps = {
  right: React.ReactNode;
  fabTracking: string;
  fabPopupContent?: React.ReactNode;
};
export const DesktopTwoColumns: React.FC<TwoColumnsProps> = ({
  right,
  fabPopupContent,
  fabTracking,
  children,
}): JSX.Element => {
  return (
    <Container>
      <Left>{children}</Left>
      <Right>
        <PositionedContactPanel>{right}</PositionedContactPanel>
      </Right>
      {fabPopupContent && (
        <FloatingContactPanel>
          <PhoneFabButton disabled={false} trackingId={fabTracking} buttonType={'button'}>
            {fabPopupContent}
          </PhoneFabButton>
        </FloatingContactPanel>
      )}
    </Container>
  );
};
