import { createGlobalStyle } from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { theme } from '../theme';
import { bodyFamily, primaryGray } from '../theme.selectors';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${bodyFamily}, sans-serif;
    ${map(theme.lineHeights.standard, (val) => `line-height: ${val}`)};
    ${map(theme.fontSizes.standard, (val) => `font-size: ${val}px`)};
    background-color: ${primaryGray};
    letter-spacing: 0;
  }
  .grecaptcha-badge {
    z-index: 100;
  }
`;
