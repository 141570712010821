import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { BaseLinkProps, buildBaseLink } from '../BaseLink';
import { linkStyles } from '../Link';

export type RouterLinkProps = LinkProps & BaseLinkProps;

const linkLink = buildBaseLink<RouterLinkProps>(Link);

const StyledBaseLink = styled(linkLink)`
  ${linkStyles}
`;

export const RouterLink = StyledBaseLink;
