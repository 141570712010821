import React from 'react';
import styled from 'styled-components';
import { Control, useController, useWatch } from 'react-hook-form';

import { Margin } from 'styled-components-spacing';
import { colorBlack } from '../../../theme.selectors';

const Fill = styled.div`
  background: ${colorBlack};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  z-index: 1;

  &::before {
    content: '';
    opacity: 1;
    width: 22.5px;
    position: absolute;
    height: 22.5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid ${colorBlack};
    border-radius: 100%;
  }
`;

const LabelStyle = styled.label`
  margin-left: 8px;
  position: relative;
  cursor: pointer;
`;

const SpanStyle = styled.span`
  margin-left: 20px;
`;

const StyledRadioInput = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 20.5px;
  height: 20.5px;
  margin: 0;
  cursor: pointer;

  &:focus + span {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    @media (-webkit-min-device-pixel-ratio: 0) {
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
    }
  }

  &:checked {
    & ~ ${Fill} {
      width: 13px;
      height: 13px;
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

export type RadioProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  name: string;
  label: string;
  id: string;
  value: string;
  onRadioClick?: () => void;
};

export const RadioButton = ({ name, label, id, control, value }: RadioProps): JSX.Element => {
  const {
    field: { onBlur, onChange, ref },
  } = useController({ name, control });
  const currentValue = useWatch({
    control,
    name,
  });

  return (
    <Margin top={{ mobile: 2, tablet: 3, desktop: 4 }}>
      <LabelStyle>
        <StyledRadioInput
          type="radio"
          value={value}
          id={id}
          onChange={onChange}
          tabIndex={0}
          onBlur={onBlur}
          name={name}
          ref={ref}
          checked={currentValue === value}
        />
        <SpanStyle>{label}</SpanStyle>
        <Fill />
      </LabelStyle>
    </Margin>
  );
};

RadioButton.displayName = 'RadioButton';
