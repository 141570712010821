import { DefaultTheme } from 'styled-components';
import {
  ThemeBreakpoints,
  ThemeColors,
  ThemeSpacing,
  ThemeFonts,
  ThemeInputs,
  FontSizes,
  LineHeights,
} from './theme.types';

declare module 'styled-components' {
  export interface DefaultTheme {
    colours: ThemeColors;
    breakpoints: ThemeBreakpoints;
    fonts: ThemeFonts;
    inputs: ThemeInputs;
    spacing: ThemeSpacing;
    fontSizes: FontSizes;
    lineHeights: LineHeights;
  }
}

const colours: ThemeColors = {
  white: '#ffffff',
  black: '#000000',
  darkGray: '#888B8D',
  gray: '#D9D9D9',
  lightGray: '#d9d9d7',
  extraLightGray: '#F2F2F2',
  primaryGray: '#FAFAFA',
  red: '#d50422',
  blue: '#0063c7',
  borderColor: '#e3e7e8',
  grayText: '#898b8d',
  bannerGray: '#747678',
  outlineBlue: '#7ea1f5',
};

const breakpoints: ThemeBreakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1028,
};

const fonts: ThemeFonts = {
  headingFamily: 'Frank Ruhl Libre',
  bodyFamily: 'Roboto',
};

const inputs: ThemeInputs = {
  fontSize: '16px',
  borderRadius: '4px',
  lineHeight: '36px',
  padding: '16px',
};

const spacing: ThemeSpacing = {
  0: '0',
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '24px',
  6: '32px',
  7: '48px',
  8: '64px',
  9: '96px',
  10: '128px',
  11: '160px',
};

const lineHeights: LineHeights = {
  standard: { mobile: 1.1, tablet: 1.1, desktop: 1.2 },
  h1: { mobile: 1.1, tablet: 1, desktop: 1.4 },
  h2: { mobile: 1.1, tablet: 1.2, desktop: 1.2 },
  h3: { mobile: 1.1, tablet: 1.1, desktop: 1 },
  h4: { mobile: 1.1, tablet: 1.1, desktop: 1.1 },
  h5: { mobile: 1.2, tablet: 1.2, desktop: 1.2 },
};

const fontSizes: FontSizes = {
  standard: { mobile: 16, tablet: 18, desktop: 16 },
  h1: { mobile: 30, tablet: 42, desktop: 52 },

  h2: { mobile: 30, tablet: 39, desktop: 39 },

  h3: { mobile: 30, tablet: 32, desktop: 30 },
  h4: { mobile: 18, tablet: 18, desktop: 26 },
  h5: { mobile: 16, tablet: 16, desktop: 16 },
};

export const theme: DefaultTheme = {
  colours,
  breakpoints,
  fonts,
  inputs,
  spacing,
  lineHeights: lineHeights,
  fontSizes: fontSizes,
};
