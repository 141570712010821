import { withAITracking } from '@microsoft/applicationinsights-react-js';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { breakpoint } from 'styled-components-breakpoint';

import { Layout, SecondaryButton, Copy } from '../../components';
import Desktop1xJpg from '../../images/error-page-hero/desktop-1x.jpg';
import Desktop2xJpg from '../../images/error-page-hero/desktop-2x.jpg';
import Mobile1xJpg from '../../images/error-page-hero/mobile-1x.jpg';
import Mobile2xJpg from '../../images/error-page-hero/mobile-2x.jpg';
import Tablet1xJpg from '../../images/error-page-hero/tablet-1x.jpg';
import Tablet2xJpg from '../../images/error-page-hero/tablet-2x.jpg';
import { reactPlugin } from '../../services/appInsights';
const StyledSecondaryButton = styled(SecondaryButton)`
  font-size: 18px;
  margin-top: 32px;
  ${breakpoint('tablet')`
    margin-top: 56px;
  `}
  ${breakpoint('desktop')`
    margin-top: 40px;
  `}
`;
const StyledCopy = styled(Copy)`
  font-size: 16px;
`;
const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};
const ServerError = (): JSX.Element => {
  const history = useHistory();
  let errorCode = getQueryVariable('error');
  const message = getQueryVariable('message');
  if (!message) {
    errorCode = '404';
  }

  return (
    <Layout
      mobile={Mobile1xJpg}
      mobile2x={Mobile2xJpg}
      tablet={Tablet1xJpg}
      tablet2x={Tablet2xJpg}
      desktop={Desktop1xJpg}
      desktop2x={Desktop2xJpg}
      heading="Oops! Something went wrong."
    >
      {errorCode && <StyledCopy>Error code {errorCode}</StyledCopy>}
      <Copy>{message || 'We can’t seem to find the page you’re looking for.'}</Copy>
      <StyledSecondaryButton onClick={() => history.push('/')} trackingId={`Success-quote-home`}>
        Your Projects
      </StyledSecondaryButton>
    </Layout>
  );
};

const PageWithTracking = withAITracking(reactPlugin, ServerError);

export const ErrorPage = PageWithTracking;
