import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { inputFontSize, inputBorderRadius, colorWhite, inputLineHeight } from '../../../theme.selectors';
type StyledTextProps = {
  error: boolean;
};

export const StyledSelect = styled.select<StyledTextProps>`
  box-sizing: border-box;
  height: 36px;
  border-radius: ${inputBorderRadius};
  font-size: ${inputFontSize};
  line-height: ${inputLineHeight};
  background-color: ${colorWhite};
  padding: 9px;
  padding-left: 16px;
  padding-right: 0;
  width: calc(100% - 16px);
  appearance: none;
  box-sizing: content-box;
  border: 1px solid ${({ error, theme }): string => (error ? theme.colours.red : theme.colours.borderColor)};
  ${breakpoint('mobile')`
  max-width: 381px;
`}
  ${breakpoint('tablet')`
  max-width: 488px;
`}
${breakpoint('desktop')`
  max-width: 576px;
`};

  &::-ms-expand {
    display: none;
  }
`;
