import React from 'react';
import styled from 'styled-components';
import { colorWhite, colorBlack, bodyFamily } from '../../../theme.selectors';
import { Heading } from '../../index';
import { Padding } from 'styled-components-spacing';

type ReasonProps = {
  description: string;
};

const ReasonContainer = styled.div`
  background-color: ${colorWhite};
  display: inline-block;
`;
const DescriptionDiv = styled.div`
  color: ${colorBlack};
  font-family: ${bodyFamily};
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 24px;
`;

export const Reason = ({ description }: ReasonProps): JSX.Element => {
  return (
    <ReasonContainer>
      <Padding horizontal={{ mobile: 2, tablet: 4 }} vertical={2}>
        <Heading.H3>Reason:</Heading.H3>
        <DescriptionDiv>
          <Padding top={2}>
            <em>{description}</em>
          </Padding>
        </DescriptionDiv>
      </Padding>
    </ReasonContainer>
  );
};
