import { ProjectFormData, QuoteFormData } from '../components/Form/ProjectForm/config';
import { Address, QuoteRequest } from './openapi.types.generated';

export const mapQuoteRequest = (
  quote: QuoteFormData | ProjectFormData,
  address: Address | null,
  reCaptchaToken: string | null,
): QuoteRequest => {
  const {
    address1,
    address2,
    company,
    description,
    developmentType,
    email,
    files,
    firstName,
    lastName,
    phone,
    knownPathway,
    owner,
    postcode,
    requestId,
    state,
    suburb,
    value,
    clientReference,
  } = quote as QuoteFormData;
  return {
    address: address ?? {
      addressLine1: address1,
      addressLine2: address2,
      postcode,
      state,
      suburb,
    },
    company,
    description,
    developmentType,
    email,
    firstName,
    id: requestId,
    knownPathway,
    lastName,
    owner: owner === 'yes',
    phone,
    price: value,
    recaptcha: reCaptchaToken,
    files,
    clientReference,
  };
};
