import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { bannerGray, colorWhite } from '../../theme.selectors';

export const LoginBanner = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${bannerGray};
  color: ${colorWhite};
  ${breakpoint('tablet')`
      height: 48px;
  `}
  & > svg {
    margin-bottom: 2px;
  }
  & > svg > g > path {
    fill: white;
  }
`;
