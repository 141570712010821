import styled, { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { theme } from '../../../theme';
import { colorBlack } from '../../../theme.selectors';

const fontSize = (val: number) => `font-size: ${val}px;`;
const lineHeight = (val: number) => `line-height: ${val};`;

const baseStyles = css`
  color: ${colorBlack};
  font-family: 'Frank Ruhl Libre';
`;

const H1 = styled.h1`
  ${baseStyles};
  margin: 0;
  font-weight: 500;
  ${map(theme.fontSizes.h1, fontSize)}
  ${map(theme.lineHeights.h1, lineHeight)}
`;

const H2 = styled.h2`
  ${baseStyles};
  margin: 0;
  font-weight: 700;
  ${map(theme.lineHeights.h2, lineHeight)};
  ${map(theme.fontSizes.h2, fontSize)};
`;

const H3 = styled.h3`
  ${baseStyles};
  margin: 0;
  font-weight: 700;
  ${map(theme.lineHeights.h3, lineHeight)};
  ${map(theme.fontSizes.h3, fontSize)};
`;

const H4 = styled.h4`
  ${baseStyles};
  margin: 0;
  font-weight: 700;
  ${map(theme.lineHeights.h4, lineHeight)};
  ${map(theme.fontSizes.h4, fontSize)};
`;
const H5 = styled.h5`
  ${baseStyles};
  margin: 0;
  font-weight: 700;
  font-family: 'Roboto';
  ${map(theme.lineHeights.h5, lineHeight)};
  ${map(theme.fontSizes.h5, fontSize)};
`;
export const Heading = {
  H1,
  H2,
  H3,
  H4,
  H5,
};
