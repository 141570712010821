import React from 'react';
import styled from 'styled-components';
import { lightGray, bodyFamily } from '../../../theme.selectors';
import { Margin } from 'styled-components-spacing';

type PaymentProps = {
  subtotal: string;
  gst: string;
  total: string;
  showDividers?: boolean;
};
const PaymentContainer = styled.div``;
const TotalSection = styled.div`
  text-align: right;
`;
const PaymentText = styled.div`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  font-family: ${bodyFamily};
  margin-right: 16px;
`;
const TotalText = styled.strong`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
`;
const StyledHr = styled.hr`
  color: ${lightGray};
  margin-bottom: 8px;
  border-style: ridge;
`;
/**
 *  View the total payment cost for payment pending tasks, so that a user knows how much they are paying
 * @param {string} subtotal - total before gst
 * @param {string} gst - the amount gst the user will pay (10% as of writing this)
 * @param {string} total - subtotal plus gst
 */
export const PaymentTotal = ({ subtotal, gst, total, showDividers = true }: PaymentProps): JSX.Element => {
  return (
    <PaymentContainer>
      <Margin bottom={2}>{showDividers && <StyledHr />}</Margin>
      <TotalSection>
        <Margin bottom={2}>
          <PaymentText>Subtotal: {subtotal}</PaymentText>
        </Margin>
        <Margin bottom={2}>
          <PaymentText>GST: {gst}</PaymentText>
        </Margin>
        <Margin bottom={1}>
          <PaymentText>
            <TotalText>Total: {total}</TotalText>
          </PaymentText>
        </Margin>
      </TotalSection>
      {showDividers && <StyledHr />}
    </PaymentContainer>
  );
};
