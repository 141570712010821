import { States, KnownPathway, DevelopmentType } from '../constants';

export type ProjectFormData = {
  requestId: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  state: States;
  address1: string;
  address2: string;
  suburb: string;
  postcode: string;
  owner: 'yes' | 'no';
  description: string;
  value: number;
  knownPathway: KnownPathway;
  developmentType: DevelopmentType;
  clientReference: string | null;
  files: string[];
};

export const projectFormDefaultValues: Partial<ProjectFormData> = {
  firstName: '',
  lastName: '',
  phone: '',
  owner: 'yes',
  address1: '',
  address2: '',
  description: '',
  suburb: '',
  clientReference: null,
};

export type QuoteFormData = {
  requestId: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  email: string;
  state: States;
  address1: string;
  address2: string;
  suburb: string;
  postcode: string;
  owner: 'yes' | 'no';
  description: string;
  value: number;
  knownPathway: KnownPathway;
  developmentType: DevelopmentType;
  clientReference: string | null;
  files: string[];
};

export const quoteFormDefaultValues: Partial<QuoteFormData> = {
  owner: 'yes',
  firstName: '',
  lastName: '',
  company: '',
  address1: '',
  address2: '',
  description: '',
  email: '',
  phone: '',
  suburb: '',
};
