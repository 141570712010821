import { Project } from '../services/openapi.types.generated';

export const addressInfo = (project: Project): string => {
  let secondLine = '';
  if (project.address?.addressLine2) {
    secondLine = project.address.addressLine2 + ',';
  }
  // project should always have address by the time it's shown in the approved portal
  // so not going to worry about the nullable property here
  // should really consider making it non-nullable.
  return `${project.address?.addressLine1}, ${secondLine}${project.address?.suburb} ${project.address?.state} ${project.address?.postcode}`;
};
