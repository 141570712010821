import React from 'react';
import { ProjectStatus } from '../services/openapi.types.generated';

type Status = ProjectStatus;

export const getProjectStatusDescription = (status: Status): Array<string | JSX.Element> => {
  const description =
    {
      [ProjectStatus.Quote]: [
        'A fee proposal has been created.',
        <br key="line-break" />,
        'Review and Accept so our team can begin the proposed work.',
      ],
      [ProjectStatus.Project]: [
        'Our team is working on your project.',
        <br key="line-break" />,
        'If you have tasks assigned to you, please upload the necessary files at your earliest convenience.',
      ],
      [ProjectStatus.ResponseRequired]: [
        'Our team have requested further information from you.',
        <br key="line-break" />,
        ,
        'Please respond at your earliest convenience.',
      ],
      [ProjectStatus.Lodged]: '',
      [ProjectStatus.Completed]: ['Our team have completed work on this project.'],
      [ProjectStatus.Initiating]: [
        'Your enquiry has been received.',
        <br key="line-break" />,
        'A member of our team will be in contact.',
      ],
      [ProjectStatus.Cancelled]: ['This cancelled project is archived for your reference.'],
    }[status] ?? [];

  return description as Array<string | JSX.Element>;
};
