import styled from 'styled-components';

type ImageProps = {
  mobile: string;
  mobile2x: string;
  tablet: string;
  tablet2x: string;
  desktop: string;
  desktop2x: string;
};

const buildQuery = (standard: string, retina: string, width?: number) => {
  const mediaWidth = width ? `and (min-width:${width}px)` : '';

  return `
    @media
    only screen ${mediaWidth} {
      background-image: url('${standard}');
    }

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2) ${mediaWidth},
    only screen and (min--moz-device-pixel-ratio: 2) ${mediaWidth},
    only screen and (-o-min-device-pixel-ratio: 2/1) ${mediaWidth},
    only screen and (min-device-pixel-ratio: 2) ${mediaWidth},
    only screen and (min-resolution: 192dpi) ${mediaWidth},
    only screen and (min-resolution: 2dppx) ${mediaWidth} {
      background-image: url('${retina}');
    }
  `;
};

export const BackgroundImage = styled.div<ImageProps>`
  ${({ mobile, mobile2x }) => buildQuery(mobile, mobile2x)}
  ${({ tablet, tablet2x, theme }) => buildQuery(tablet, tablet2x, theme.breakpoints.tablet)}
  ${({ desktop, desktop2x, theme }) => buildQuery(desktop, desktop2x, theme.breakpoints.desktop)}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
