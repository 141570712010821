import { Empty } from 'antd';
import React from 'react';
import { useLoading } from '../../context/LoadingContext';

import { ProjectsGrid, ProjectsGridSkeleton } from './ProjectsGrid';
import { ProjectFiltersSection } from './ProjectsGrid/ProjectFilterSection';
import { ProjectsTable } from './ProjectsTable';
import { ProjectsTableSkeleton } from './ProjectsTable/Skeleton';
import { GridSection, StyledFilters, TableSection } from './styles';
import { DashboardGridProps } from './types';

export const DashboardGrid = ({
  idText,
  setIdText,
  addressText,
  setAddressText,
  clientReferenceText,
  setClientReferenceText,
  projectsData = [],
  sortBy,
  setSortBy,
  setSortOrder,
  sortOrder,
  showFilter,
  setShowFilter,
  filterStatus,
  setFilterStatus,
}: DashboardGridProps): JSX.Element => {
  return (
    <>
      <DesktopDashboard
        projectsData={projectsData}
        setAddressText={setAddressText}
        addressText={addressText}
        setClientReferenceText={setClientReferenceText}
        clientReferenceText={clientReferenceText}
        setIdText={setIdText}
        idText={idText}
        setFilterStatus={setFilterStatus}
        filterStatus={filterStatus}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        setSortBy={setSortBy}
        sortBy={sortBy}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
      />

      <MobileDashboard
        projectsData={projectsData}
        setAddressText={setAddressText}
        addressText={addressText}
        setClientReferenceText={setClientReferenceText}
        clientReferenceText={clientReferenceText}
        setIdText={setIdText}
        idText={idText}
        setFilterStatus={setFilterStatus}
        filterStatus={filterStatus}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        setSortBy={setSortBy}
        sortBy={sortBy}
        setSortOrder={setSortOrder}
        sortOrder={sortOrder}
      />
    </>
  );
};

const DesktopDashboard = ({
  idText,
  setIdText,
  addressText,
  setAddressText,
  clientReferenceText,
  setClientReferenceText,
  projectsData = [],
  sortBy,
  setSortBy,
  setSortOrder,
  sortOrder,
  filterStatus,
  setFilterStatus,
}: DashboardGridProps) => {
  const { loading } = useLoading();
  const showProjects = !loading && projectsData?.length;

  return (
    <TableSection>
      <StyledFilters
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        idText={idText}
        setIdText={setIdText}
        clientReferenceText={clientReferenceText}
        setClientReferenceText={setClientReferenceText}
        addressText={addressText}
        setAddressText={setAddressText}
      />

      {loading ? (
        <ProjectsTableSkeleton />
      ) : showProjects ? (
        <ProjectsTable
          gridData={projectsData}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          idText={idText}
          setIdText={setIdText}
          addressText={addressText}
          setAddressText={setAddressText}
          clientReferenceText={clientReferenceText}
          setClientReferenceText={setClientReferenceText}
          filterStatus={filterStatus}
          setFilterStatus={setFilterStatus}
        />
      ) : (
        <Empty description="No results for your search" />
      )}
    </TableSection>
  );
};

const MobileDashboard: React.FC<DashboardGridProps> = ({
  idText,
  setIdText,
  addressText,
  setAddressText,
  clientReferenceText,
  setClientReferenceText,
  projectsData = [],
  sortBy,
  setSortBy,
  setSortOrder,
  sortOrder,
  filterStatus,
  setFilterStatus,
  setShowFilter,
  showFilter,
}: DashboardGridProps) => {
  const { loading } = useLoading();

  const showProjects = !loading && projectsData?.length;

  return (
    <GridSection>
      <ProjectFiltersSection
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        idText={idText}
        setIdText={setIdText}
        addressText={addressText}
        setAddressText={setAddressText}
        clientReferenceText={clientReferenceText}
        setClientReferenceText={setClientReferenceText}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
      />

      {loading ? (
        <ProjectsGridSkeleton />
      ) : showProjects ? (
        <ProjectsGrid gridData={projectsData} />
      ) : (
        <Empty description="No results for your search" />
      )}
    </GridSection>
  );
};
