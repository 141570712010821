import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Padding } from 'styled-components-spacing';
import { ReactComponent as ApprovedIcon } from '../../../svg/approved.svg';
import { ReactComponent as UserIcon } from '../../../svg/user.svg';
import { borderColor, colorBlack, colorRed, colorWhite } from '../../../theme.selectors';
import { Copy } from '../../index';
import { Task } from '../../../services/openapi.types.generated';
import numeral from 'numeral';

type TaskProps = {
  title: string;
  task: Task;
  longDescription?: string;
  cost: string;
  gst?: string;
  discount?: string;
  quantity?: string;
  onButtonClick?: (task: Task) => void;
};

const TaskContainer = styled.div<{ reduceShadow: boolean }>`
  background-color: ${colorWhite};
  border-color: ${borderColor};
  width: 100%;
  min-height: 107px;
  box-shadow: 2px 2px 16px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  &:hover {
    box-shadow: 2px 2px 16px 1px rgba(0, 0, 0, 0.2);
  }
  ${({ reduceShadow }) => (reduceShadow ? `box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.1) !important;` : '')}
`;
const TopDiv = styled.div`
  display: flex;
  height: 60%;
`;
const BottomDiv = styled(Padding)`
  background-color: ${({ theme }): string => theme.colours.extraLightGray};
  border: ${({ theme }): string => `1px solid ${theme.colours.extraLightGray}`};
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  border-radius: 0 0 8px 8px;
`;
const PriceDetailsWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

const PriceDetail = styled.div`
  display: flex;
  text-align: left;
`;

const Cost = styled.span<{ assignedToUser: boolean }>`
  text-decoration: ${({ assignedToUser }): string => (assignedToUser ? 'line-through' : 'initial')};
`;

const PriceDetailLabel = styled.div`
  color: ${({ theme }) => theme.colours.grayText};
  margin-right: 8px;
`;

const IconDiv = styled.div<{ assignedToUser: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 67px;
  background-color: ${({ theme }): string => theme.colours.white};
  width: 87px;
  & > svg {
    fill: ${colorBlack};
  }
  & > svg g {
    fill: ${colorBlack};
  }
`;
const TopTextDiv = styled.div<{ userCanAssign: boolean }>`
  margin-left: 16px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 16px;
  margin-right: 16px;
`;

const LongDescription = styled(Padding)`
  font-size: 14px;
`;

const StyledStrong = styled.strong`
  font-weight: 500;
`;
const BiggerCopy = styled(Copy)`
  margin: 0;
  ${breakpoint('mobile')`
    font-size: 18px;
  `}
  ${breakpoint('tablet')`
    font-size: 18px;
  `}
  ${breakpoint('desktop')`
    font-size: 21px;
  `}
`;
const iconStyling = css`
  width: 29px;
  height: 29px;
  margin-left: 3px;
  ${breakpoint('tablet')`
  width: 34px;
  height: 34px;
`}
  ${breakpoint('desktop')`
  height: 32px;
  width: 32px;
`};
`;
const StyledApprovedIcon = styled(ApprovedIcon)`
  ${iconStyling}
`;
const StyledUser = styled(UserIcon)`
  ${iconStyling}
  fill: black;

  & g {
    fill: black;
  }
`;
const AssignTask = styled.div`
  color: ${colorRed};
  word-break: keep-all;
  font-size: 16px;
  text-decoration: underline;
  &:hover {
    filter: brightness(90%);
  }
  &:active {
    filter: brightness(70%);
  }
`;
const ActionAssignContainer = styled.div`
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ConfirmTask = ({
  task,
  title,
  longDescription,
  cost,
  discount,
  quantity,
  onButtonClick,
}: TaskProps): JSX.Element => {
  const assignedToUser = task.userCanAssignTask && task.assignedTo.toLowerCase() !== 'approved';
  const [clickDown, setClickDown] = useState(false);

  const reassignKeyClick = (e: React.KeyboardEvent) => {
    if (e.key == 'Enter') {
      reassignButtonClick();
    }
  };
  const reassignButtonClick = () => {
    task.assignedTo = assignedToUser ? 'approved' : 'user';
    if (onButtonClick) {
      onButtonClick(task);
    }
  };

  const icon = assignedToUser ? <StyledUser /> : <StyledApprovedIcon />;

  const actionButton = (
    <AssignTask
      tabIndex={0}
      onKeyPress={reassignKeyClick}
      onMouseDown={() => setClickDown(true)}
      onMouseUp={() => setClickDown(false)}
      onBlur={() => setClickDown(false)}
      onClick={reassignButtonClick}
    >
      {assignedToUser ? 'Assign to Approved' : 'Assign to me'}
    </AssignTask>
  );
  return (
    <TaskContainer reduceShadow={clickDown}>
      <TopDiv>
        <IconDiv assignedToUser={assignedToUser}>{icon}</IconDiv>
        <TopTextDiv userCanAssign={task.userCanAssignTask}>
          <TitleWrapper>
            <BiggerCopy>
              <StyledStrong>{title}</StyledStrong>
            </BiggerCopy>
          </TitleWrapper>
          <LongDescription vertical={3}>{longDescription}</LongDescription>
        </TopTextDiv>
      </TopDiv>

      <BottomDiv all={3}>
        {task.userCanAssignTask && <ActionAssignContainer>{actionButton}</ActionAssignContainer>}
        <PriceDetailsWrapper>
          {quantity && Number(quantity) > 1 && (
            <PriceDetail>
              <PriceDetailLabel>Units</PriceDetailLabel>
              <span>{quantity}</span>
            </PriceDetail>
          )}
        </PriceDetailsWrapper>
        <PriceDetailsWrapper>
          <PriceDetail>
            <PriceDetailLabel>Price</PriceDetailLabel>
            <Cost assignedToUser={assignedToUser}>{cost}</Cost>
          </PriceDetail>
          {discount && (numeral(discount)?.value() || 0) > 0 && (
            <PriceDetail>
              <PriceDetailLabel>Discount</PriceDetailLabel>
              <Cost assignedToUser={assignedToUser}>{discount}</Cost>
            </PriceDetail>
          )}
        </PriceDetailsWrapper>
      </BottomDiv>
    </TaskContainer>
  );
};
