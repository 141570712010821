import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React, { useEffect, useState } from 'react';
import { Props as ReactModalProps } from 'react-modal';
import styled, { useTheme } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { ReactComponent as CallIcon } from '../../../svg/call.svg';
import { ReactComponent as Close } from '../../../svg/close.svg';
import { colorWhite, extraLightGray } from '../../../theme.selectors';
import { BaseModal } from '../../Modal/BaseModal';
import { BaseFabButton } from '../index';

type PhoneFabButtonProps = {
  children: React.ReactNode;
  trackingId: string;
  className?: string;
  disabled?: boolean;
  buttonType?: 'button' | 'submit' | 'reset';
};
const PhoneBase = styled(BaseFabButton)`
  width: 62px;
  height: 62px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: fixed;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  background-color: ${colorWhite};
`;
const StyledModal = styled(BaseModal)`
  background: transparent;
  border: none;
  top: 50%;
  position: absolute;
  left: 50%;
  right: auto;
  bottom: auto;
  margin: 0 auto;
  width: 100% !important;
  transform: translate(-50%, -50%);
  & > div {
    display: flex;
    justify-content: center;
  }
  &:focus {
    outline: none;
  }
  & > div > div {
    display: flex;
    justify-content: center;
    ${breakpoint('mobile')`
    padding-left: 2px;
    padding-right: 2px;
    `}
    ${breakpoint('tablet')`
      width: auto;
    `}
  }
`;
const CloseContainer = styled.div`
  float: right;
  cursor: pointer;
  height: 16px;
  z-index: 1;
  ${breakpoint('mobile')`
    margin-bottom: -2em;
    margin-top: 0.5em;
    margin-left: -2em;
    margin-right: 1em;
  `}
  ${breakpoint('tablet')`
    margin-bottom: -2em;
    margin-top: 0.5em;
    margin-left: -2em;
    margin-right: 1em;
  `}
`;
const StyledCloseButton = styled(Close)`
  height: 20px;
  width: 20px;
  rect {
    fill: ${extraLightGray};
  }
`;
export const PhoneFabButton = ({
  buttonType = 'button',
  children,
  className,
  trackingId,
  disabled,
}: PhoneFabButtonProps): JSX.Element => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return function cleanupListener() {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  /**
   * If the modal is open and the user resizes the window to be greater than the
   * fab breakpoint then we will want to close that modal.
   */
  const handleResize = () => {
    if (window.innerWidth >= theme.breakpoints.desktop) {
      closeModal();
    }
  };
  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  };
  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'scroll';
  };
  const appInsights = useAppInsightsContext();
  const handleClick = () => {
    appInsights.trackEvent({ name: trackingId });
    openModal();
  };
  const reactModalProps: ReactModalProps = {
    isOpen: modalIsOpen,
    shouldCloseOnOverlayClick: true,
    onRequestClose: closeModal,
  };
  return (
    <>
      <PhoneBase
        buttonType={buttonType}
        trackingId={trackingId}
        className={className}
        disabled={disabled}
        onClick={handleClick}
      >
        <CallIcon />
      </PhoneBase>
      <StyledModal reactModalProps={reactModalProps}>
        {children}
        <CloseContainer onClick={closeModal}>
          <StyledCloseButton />
        </CloseContainer>{' '}
      </StyledModal>
    </>
  );
};
