import { DefaultTheme, ThemeProps } from 'styled-components';

type ThemeSelector<T> = (props: ThemeProps<DefaultTheme>) => T;

const theme = (props: ThemeProps<DefaultTheme>) => props.theme;
const colours = (props: ThemeProps<DefaultTheme>) => theme(props).colours;
const fonts = (props: ThemeProps<DefaultTheme>) => theme(props).fonts;
const inputs = (props: ThemeProps<DefaultTheme>) => theme(props).inputs;

export const colorBlack: ThemeSelector<string> = (props) => colours(props).black;
export const colorWhite: ThemeSelector<string> = (props) => colours(props).white;
export const colorRed: ThemeSelector<string> = (props) => colours(props).red;
export const darkGray: ThemeSelector<string> = (props) => colours(props).darkGray;
export const gray: ThemeSelector<string> = (props) => colours(props).gray;
export const extraLightGray: ThemeSelector<string> = (props) => colours(props).extraLightGray;
export const primaryGray: ThemeSelector<string> = (props) => colours(props).primaryGray;
export const colorBlue: ThemeSelector<string> = (props) => colours(props).blue;
export const grayText: ThemeSelector<string> = (props) => colours(props).grayText;
export const borderColor: ThemeSelector<string> = (props) => colours(props).borderColor;
export const lightGray: ThemeSelector<string> = (props) => colours(props).lightGray;
export const bannerGray: ThemeSelector<string> = (props) => colours(props).bannerGray;
export const outlineBlue: ThemeSelector<string> = (props) => colours(props).outlineBlue;

export const headingFamily: ThemeSelector<string> = (props) => fonts(props).headingFamily;
export const bodyFamily: ThemeSelector<string> = (props) => fonts(props).bodyFamily;

export const inputFontSize: ThemeSelector<string> = (props) => inputs(props).fontSize;
export const inputBorderRadius: ThemeSelector<string> = (props) => inputs(props).borderRadius;
export const inputLineHeight: ThemeSelector<string> = (props) => inputs(props).lineHeight;
export const inputPadding: ThemeSelector<string> = (props) => inputs(props).padding;
