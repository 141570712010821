import { ProjectStatus, Task } from '../../../services/openapi.types.generated';

type TaskDownloadsInfo = Pick<Task, 'payment' | 'assignedTo' | 'files' | 'status'> & {
  projectStatus: ProjectStatus;
};

export const hasImportantInformation = (taskInfo: TaskDownloadsInfo): boolean => {
  const { assignedTo } = taskInfo;

  const showDropDown = shouldShowDropdown(taskInfo);
  return assignedTo === 'user' && showDropDown;
};

export const shouldShowDropdown = ({
  payment,
  assignedTo,
  status,
  files,
  projectStatus,
}: TaskDownloadsInfo): boolean => {
  const isCompleted = status === 'COMPLETE' || projectStatus === ProjectStatus.Completed;
  return !!files?.length || (payment?.status === 'paid' && assignedTo === 'user' && !isCompleted);
};
