import { AddressDetails } from '.';
import { Address } from '../../../services/openapi.types.generated';

export const addressMap = (details: AddressDetails, gurasId: string, planningPortalId: string): Address => {
  const result: Address = {
    fullAddress: `${details.HSDETAILS} ${details.ROAD} ${details.SUBURBNAME} ${details.POSTCODE}`,
    addressLine1: `${details.HSDETAILS} ${details.ROAD}`,
    addressLine2: null,
    suburb: details.SUBURBNAME,
    state: 'NSW',
    postcode: String(details.POSTCODE),
    gurasId: String(details.GURASID),
    planningPortalId: String(planningPortalId),
    latitude: undefined,
    longitude: undefined,
    manualEntry: gurasId ? false : true,
  };

  const trimmedResult = {
    ...result,
    addressLine1: result.addressLine1?.replace(/\s+/g, ' ').trim(),
    addressLine2: result.addressLine2?.replace(/\s+/g, ' ').trim(),
  };

  return trimmedResult;
};
