import { reverse } from 'lodash';
import { Milestone } from '../../services/openapi.types.generated';

export const getCurrentMilestone = (milestones: Milestone[]): Milestone | undefined => {
  if (milestones.length === 0) {
    return undefined;
  }

  const reversed = reverse([...milestones]);

  return reversed.find(({ tasks }) => tasks?.length);
};
