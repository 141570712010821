import React from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Button } from 'antd';
import { ButtonHTMLType } from 'antd/lib/button/button';

export type BaseButtonProps = {
  children: React.ReactNode;
  trackingId: string;
  className?: string;
  disabled?: boolean;
  buttonType?: ButtonHTMLType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading?: boolean;
};

export const BaseButton = ({
  buttonType = 'button',
  children,
  onClick,
  className,
  trackingId,
  disabled = false,
  loading = false,
}: BaseButtonProps): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    appInsights.trackEvent({ name: trackingId });
    onClick && onClick(event);
  };
  return (
    <Button disabled={disabled} htmlType={buttonType} className={className} onClick={handleClick} loading={loading}>
      {children}
    </Button>
  );
};
