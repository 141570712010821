export type StatusFilters =
  | 'all'
  | 'quote'
  | 'in-progress'
  | 'responseRequired'
  | 'completed'
  | 'cancelled'
  | 'enquiryReceived';

export const statusOptions: Array<{ label: string; value: StatusFilters }> = [
  { label: 'All', value: 'all' },
  { label: 'Pending Proposal', value: 'quote' },
  { label: 'In Progress', value: 'in-progress' },
  { label: 'Awaiting Information', value: 'responseRequired' },
  { label: 'Completed', value: 'completed' },
  { label: 'Archived', value: 'cancelled' },
  { label: 'Enquiry Received', value: 'enquiryReceived' },
];
