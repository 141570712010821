import React, { useContext } from 'react';
type Props = {
  title: string;
  setTitle: (title: string) => void;
};

export const PageTitleContext = React.createContext<Props>({
  title: '',
  setTitle: () => {
    return;
  },
});

export const usePageTitle = (): Props => useContext(PageTitleContext);
