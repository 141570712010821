import React from 'react';
import styled from 'styled-components';
import { Project } from '../../../services/openapi.types.generated';
import { DownloadDocumentsButton } from '../DownloadDocumentsButton';

const CongratsBox = styled.div`
  width: auto;
  height: auto;
  margin: -40px 50px 20px;
  font-family: 'Frank Ruhl Libre';
`;

const CongratsBoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px;
`;

type Props = {
  subject: Project;
};

export const ProjectCompleteMessage: React.FC<Props> = (props: Props) => {
  const { subject } = props;

  return (
    <CongratsBox>
      <CongratsBoxContent>
        <h1>Congratulations!</h1>
        <h3>The project has been completed and your documents are ready for download.</h3>
        <br />
        <DownloadDocumentsButton subject={subject} />
        <br />
        <h3 style={{ marginTop: '10px' }}>
          Thanks for using <b>Approved</b>!
        </h3>
      </CongratsBoxContent>
    </CongratsBox>
  );
};
