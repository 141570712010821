import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ProjectsGridProps } from '../types';
import { ProjectStatusMap } from '../../../utils/statusMaps';
import { ProjectStatus } from '../../../services/openapi.types.generated';
import { Card, Descriptions, Skeleton, Typography } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import { range } from 'lodash';
import { getProjectStatusDescription } from '../../../utils/projectStatusDescriptions';
import { HelpIcon } from '../../HelpIcon';
import { getRoute } from '../utils';

const StyledSkeleton = styled(Skeleton)`
  ul li {
    margin: 0;
  }
`;

const GridColumn = styled.div`
  ${({ disabled }: { disabled: boolean }) => `cursor: ${disabled ? 'not-allowed' : 'pointer'}`};
  ${({ disabled }: { disabled: boolean }) => `pointer-events: ${disabled ? 'none' : 'auto'}`};
  &:hover {
    ${({ disabled }: { disabled: boolean }) => `opacity: ${disabled ? '1' : '0.6'}`};
  }
`;

type Props = Pick<ProjectsGridProps, 'gridData'>;

export const ProjectsGrid: React.FC<Props> = ({ gridData }: Props) => {
  const history = useHistory();

  return (
    <>
      {gridData.map((data) => {
        const initiating = data.status === ProjectStatus.Initiating;
        const route = getRoute(data);

        return (
          <GridColumn
            disabled={initiating}
            tabIndex={0}
            key={data.id}
            onKeyPress={(e: React.KeyboardEvent) => {
              e.preventDefault();
              if (e.key == 'Enter') {
                history.push(route);
              }
            }}
            onClick={() => {
              history.push(route);
            }}
          >
            <Card
              style={{ margin: '16px 0' }}
              type="inner"
              headStyle={{ background: '#f4f4f4' }}
              title={<>Project {data.applicationId}&nbsp;</>}
              extra={
                <>
                  &nbsp;
                  {data.referenceId ? (
                    <>
                      Your reference <b>{data.referenceId}</b>
                    </>
                  ) : null}
                </>
              }
            >
              <Descriptions layout="horizontal" column={1}>
                <DescriptionsItem label={<Typography.Text type="secondary">Site address</Typography.Text>}>
                  <Typography.Text strong style={{ fontWeight: 500, overflowWrap: 'break-word' }}>
                    {data?.address?.fullAddress}
                  </Typography.Text>
                </DescriptionsItem>
                <DescriptionsItem label={<Typography.Text type="secondary">Pathway</Typography.Text>}>
                  <Typography.Text ellipsis strong style={{ fontWeight: 500 }}>
                    {data.pathway.name || '-'}
                  </Typography.Text>
                </DescriptionsItem>
                <DescriptionsItem label={<Typography.Text type="secondary">Est. completion</Typography.Text>}>
                  <Typography.Text ellipsis strong style={{ fontWeight: 500 }}>
                    {data.estimatedCompletionDate || '-'}
                  </Typography.Text>
                </DescriptionsItem>
                <DescriptionsItem label={<Typography.Text type="secondary">Status</Typography.Text>}>
                  <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography.Text ellipsis strong style={{ fontWeight: 500 }}>
                      {ProjectStatusMap[data.status] || 'Unknown'}
                    </Typography.Text>
                    <HelpIcon style={{ width: '28px', height: '28px' }} id="projectTooltip">
                      {getProjectStatusDescription(data.status)}
                    </HelpIcon>
                  </span>
                </DescriptionsItem>
              </Descriptions>
            </Card>
          </GridColumn>
        );
      })}
    </>
  );
};

export const ProjectsGridSkeleton: React.FC = () => {
  return (
    <>
      {range(0, 5).map((index) => (
        <GridColumn disabled={false} tabIndex={0} key={`skeleton-${index}`}>
          <Card
            style={{ margin: '16px 0' }}
            type="inner"
            headStyle={{ background: '#f4f4f4' }}
            title={<>Loading project... &nbsp;</>}
            extra={<>&nbsp;</>}
          >
            <Descriptions layout="horizontal" column={1}>
              <DescriptionsItem
                style={{ paddingBottom: 5 }}
                label={<Typography.Text type="secondary">Site address</Typography.Text>}
              >
                <Typography.Text strong style={{ fontWeight: 500 }}>
                  <StyledSkeleton style={{ width: 320 }} active paragraph={{ rows: 1 }} title={false} />
                </Typography.Text>
              </DescriptionsItem>

              <DescriptionsItem
                style={{ paddingBottom: 3 }}
                label={<Typography.Text type="secondary">Pathway</Typography.Text>}
              >
                <Typography.Text ellipsis strong style={{ fontWeight: 500 }}>
                  <StyledSkeleton style={{ width: 280 }} active paragraph={{ rows: 1 }} title={false} />
                </Typography.Text>
              </DescriptionsItem>

              <DescriptionsItem
                style={{ paddingBottom: 3, display: 'inline-block' }}
                label={<Typography.Text type="secondary">Est. completion</Typography.Text>}
              >
                <Typography.Text ellipsis strong style={{ fontWeight: 500 }}>
                  <StyledSkeleton style={{ width: 150 }} active paragraph={{ rows: 1 }} title={false} />
                </Typography.Text>
              </DescriptionsItem>

              <DescriptionsItem
                style={{ paddingBottom: 3, display: 'inline-block' }}
                label={<Typography.Text type="secondary">Status</Typography.Text>}
              >
                <Typography.Text ellipsis strong style={{ fontWeight: 500 }}>
                  <StyledSkeleton style={{ width: 150 }} active paragraph={{ rows: 1 }} title={false} />
                </Typography.Text>
              </DescriptionsItem>
            </Descriptions>
          </Card>
        </GridColumn>
      ))}
    </>
  );
};
