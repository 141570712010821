import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Project, Quote } from '../services/openapi.types.generated';

type Location = ReturnType<typeof useLocation>;

export type Subject = Quote | Project;

const assertIsProject = (location: Location) => {
  return location.pathname.startsWith('/projects/') || location.pathname.startsWith('/projects');
};

export function useSubject(): 'projects' | 'quotes' {
  const location = useLocation();

  const initialValue = assertIsProject(location);
  const [isProject, setIsProject] = useState<boolean>(initialValue);

  useEffect(() => {
    setIsProject(assertIsProject(location));
  }, [location]);

  return isProject ? 'projects' : 'quotes';
}
