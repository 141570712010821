import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Padding, Margin } from 'styled-components-spacing';
import { Project, UploadedFile } from '../../../services/openapi.types.generated';
import { ReactComponent as FolderIcon } from '../../../svg/folder.svg';
import { borderColor } from '../../../theme.selectors';
import { formatSize } from '../../helpers';
import { ProjectFileUpload } from '../ProjectFileUpload';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { Link } from '../../Link';

type TaskProps = {
  files?: UploadedFile[];
  taskId: string;
  projectId: string;
  canUpload: boolean;
  setProject?: (project: Project) => void;
  executeRecaptcha: () => Promise<string | null>;
};

const StyledCopy = css`
  font-size: 14px;
  line-height: 16px;
  ${breakpoint('tablet')`
  font-size: 16px;
  line-height: 19px;
  `}
`;

const Container = styled(Padding)`
  display: block;
  position: relative;
  border-top: 1px solid ${borderColor};
`;

const DownloadsHeader = styled.div``;

const FilesSubmittedCopy = styled.div`
  ${StyledCopy}
  ${breakpoint('mobile', 'tablet')`
    float: none;
  `}
  font-size: 18px;
  margin: 16px 0;
  font-weight: 500;
  float: left;
`;

const StyledFileUpload = styled(ProjectFileUpload)`
  ${breakpoint('mobile', 'tablet')`
    display: block;
  `}
  button {
    width: 100%;
  }
`;

const FileCopy = css`
  font-size: 16px;
  line-height: 19px;
  ${breakpoint('tablet')`
  font-size: 18PX;
  line-height: 21PX;
`}
`;

const FileItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
  ${breakpoint('tablet')`
    flex-direction: row;
    align-items: center;
  `}
`;

const FileName = styled.div`
  ${FileCopy}
  display: flex;
  flex: 1 1 80%;
  align-items: center;
`;

const FileDate = styled.div`
  opacity: 0.6;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 42px;
  margin-top: 4px;
  min-width: 120px;
  ${breakpoint('tablet')`
    margin-top: 0;
    margin-left: 0;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    flex: 1 1 30%;
    text-align: right;
  `}
  ${breakpoint('desktop')`
    font-size: 16px;
    line-height: 19px;
  `}
`;

const StyledFolder = styled(FolderIcon)`
  margin-right: 15px;
  margin-top: -4px;
`;

const FileNameDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 400px;
  text-overflow: ellipsis;
  ${breakpoint('tablet')`
  width: 300px;
  `}
`;
const FileSize = styled.div`
  opacity: 0.8;
  display: inline;
  margin-left: 15px;
  font-size: 15px;
  min-width: 70px;
`;
export const TaskUploads = ({
  files,
  taskId,
  projectId,
  setProject,
  canUpload,
  executeRecaptcha,
}: TaskProps): JSX.Element => {
  const [, getDownloadLink] = useApiRequest<{ downloadUrl: string }>(
    {
      method: 'get',
    },
    { manual: true, authRedirect: true },
  );

  return (
    <Container all={{ mobile: 3, tablet: 4, desktop: 5 }}>
      <DownloadsHeader>
        {canUpload || files?.length ? <FilesSubmittedCopy>{files?.length} files submitted</FilesSubmittedCopy> : null}
        {canUpload && (
          <StyledFileUpload
            projectId={projectId}
            taskId={taskId}
            setProject={setProject}
            executeRecaptcha={executeRecaptcha}
          />
        )}
      </DownloadsHeader>
      {files?.length ? (
        <Margin top={5}>
          {files?.map((file) => {
            let fileSize = '0kb';
            if (file.size) {
              fileSize = formatSize(file.size);
            }
            const date = new Date(file.uploadedDate);
            return (
              <FileItem key={file.id}>
                <FileName>
                  <StyledFolder />{' '}
                  <FileNameDiv>
                    <Link
                      onClick={async () => {
                        const response = await getDownloadLink({ url: `/documents/${file.id}` });
                        const url = response.data.downloadUrl;
                        const result = await axios.get(url, {
                          responseType: 'blob',
                        });

                        const blob = result.data as Blob;
                        fileDownload(blob, file.name ?? '');
                      }}
                    >
                      {file.name}
                    </Link>
                  </FileNameDiv>
                  <FileSize>({fileSize})</FileSize>
                </FileName>
                <FileDate>Uploaded: {date.toLocaleDateString('en-AU')}</FileDate>
              </FileItem>
            );
          })}
        </Margin>
      ) : null}
    </Container>
  );
};
