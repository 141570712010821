import { range } from 'lodash';
import React from 'react';
import {
  EllipsTd,
  HeadTr,
  StyledSkeleton,
  StyledTable,
  tableColumnProps,
  TableSection,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '../styles';

export const ProjectsTableSkeleton: React.FC = () => {
  return (
    <TableSection>
      <StyledTable>
        <Thead>
          <HeadTr>
            <Th {...tableColumnProps['application']}>Project</Th>
            <Th {...tableColumnProps['reference']}>Your reference</Th>
            <Th {...tableColumnProps['address']}>Site address</Th>
            <Th {...tableColumnProps['pathway']}>Pathway</Th>
            <Th {...tableColumnProps['completionDate']}>Est. completion</Th>
            <Th {...tableColumnProps['status']}>Status</Th>
          </HeadTr>
        </Thead>

        <Tbody>
          {range(0, 5).map((index) => (
            <Tr disabled={false} key={`skeleton-${index}`} tabIndex={0}>
              <EllipsTd {...tableColumnProps['application']}>
                {<StyledSkeleton active paragraph={{ rows: 1 }} title={false} />}
              </EllipsTd>
              <Td {...tableColumnProps['reference']}>
                {<StyledSkeleton active paragraph={{ rows: 1 }} title={false} />}
              </Td>
              <EllipsTd {...tableColumnProps['address']}>
                {<StyledSkeleton active paragraph={{ rows: 1 }} title={false} />}
              </EllipsTd>
              <EllipsTd {...tableColumnProps['pathway']}>
                {<StyledSkeleton active paragraph={{ rows: 1 }} title={false} />}
              </EllipsTd>
              <Td {...tableColumnProps['completionDate']}>
                {<StyledSkeleton active paragraph={{ rows: 1 }} title={false} />}
              </Td>
              <Td {...tableColumnProps['status']}>{<StyledSkeleton active paragraph={{ rows: 1 }} title={false} />}</Td>
            </Tr>
          ))}
        </Tbody>
      </StyledTable>
    </TableSection>
  );
};
