import React, { useEffect } from 'react';
import { AuthError, InteractionType, RedirectRequest } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { Route, RouteProps } from 'react-router-dom';
import { StyledModal } from '../StyledModal';
import { Loading } from '../Loading';

type AuthenticatedRouteProps = { component: React.FunctionComponent | React.ComponentClass } & RouteProps;

export const authenticationRequest: RedirectRequest = {
  redirectUri: '/',
  scopes: [],
};

const AuthErrorComponent: React.FC<{ error: AuthError | null }> = ({ error }) => {
  const msalContext = useMsal();
  useEffect(() => {
    if (error) msalContext.instance.loginRedirect(authenticationRequest);
  }, [error]);
  return null;
};

export const AuthenticatedRoute = ({ component: Component, ...rest }: AuthenticatedRouteProps): JSX.Element => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          loadingComponent={() => (
            <StyledModal isOpen={true} style={{ overlay: { zIndex: 5 } }}>
              <Loading />
            </StyledModal>
          )}
          errorComponent={AuthErrorComponent}
          authenticationRequest={authenticationRequest}
        >
          <Component {...props} />
        </MsalAuthenticationTemplate>
      )}
    />
  );
};
