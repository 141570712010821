import React, { useEffect } from 'react';
import { usePageTitle } from '../../context/PageTitleContext';
import { WithExpertPanel } from '../../components/Layout/withExperModal.hoc';
import { WithSubject, WithSubjectProps } from '../../components/Layout/withSubject.hoc';
import { WithLayout } from '../../components/Layout/withLayout.hoc';
import { BaseSuccess } from '../../components/BaseSuccess';

type PaymentSuccessProps = WithSubjectProps;
const Component: React.FC<PaymentSuccessProps> = ({ subject }) => {
  const { setTitle } = usePageTitle();
  useEffect(() => setTitle(`Fee Proposal ${subject?.applicationId ?? 'Paid'}`), [subject]);

  const markdown = `# What happens next?
  1. Approved will review your documentation and be in touch if further information is required.
  2. Once we have confirmed we have everything we need, we'll start working towards getting your project **Approved**!`;

  const description = 'This quote has already been accepted.';

  return <BaseSuccess title="Thanks!" description={description} markdown={markdown} />;
};

export const AlreadyAccepted = WithSubject(WithLayout(WithExpertPanel(Component)));
