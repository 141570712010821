import React, { useState } from 'react';
import { Props as ReactModalProps } from 'react-modal';
import { useModal } from 'react-modal-hook';
import { PrimaryButton } from '../../Button';
import { BaseModal } from '../BaseModal';

export type AlertModalProps = {
  title: string;
  description?: string | JSX.Element;
  closeActionText?: string;
  trackingPrefix: string;
  isOpen: boolean;
  onClose?: () => void;
};

export const AlertModal = ({
  title,
  description,
  closeActionText = 'Okay',
  trackingPrefix,
  isOpen,
  onClose,
}: AlertModalProps): JSX.Element => {
  const primaryAction = (
    <PrimaryButton trackingId={`${trackingPrefix}-close`} onClick={onClose}>
      {closeActionText}
    </PrimaryButton>
  );
  const reactModalProps: ReactModalProps = {
    isOpen,
    onRequestClose: onClose,
  };
  return (
    <BaseModal title={title} primaryAction={primaryAction} reactModalProps={reactModalProps}>
      {description}
    </BaseModal>
  );
};

type HookAlertModalProps = Omit<AlertModalProps, 'isOpen'>;
type ShowAlertModal = (props: HookAlertModalProps) => void;
type HideAlertModal = () => void;

export const useAlertModal = (): [ShowAlertModal, HideAlertModal] => {
  const [alertModalProps, setAlertModalProps] = useState<AlertModalProps>({
    isOpen: true,
    title: '',
    trackingPrefix: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
  });

  const [showModal, hideModal] = useModal(() => <AlertModal {...alertModalProps} isOpen />, [alertModalProps]);

  const showAlertModal: ShowAlertModal = (props) => {
    const onClose = () => {
      props.onClose && props.onClose();
      hideModal();
    };
    const newAlertModalProps: AlertModalProps = { ...props, onClose, isOpen: true };
    setAlertModalProps(newAlertModalProps);
    showModal();
  };

  return [showAlertModal, hideModal];
};
