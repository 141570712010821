import React, { ElementType } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { colorBlack, headingFamily } from '../../theme.selectors';

type LinkOverrides = {
  [key: string]: () => void;
};

type MarkdownProps = {
  markdownText: string;
  linkOverrides?: LinkOverrides;
};
const StyledMarkdown = styled(ReactMarkdown)`
  font-size: 18px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${colorBlack};
    font-family: ${headingFamily};
    line-height: 1.2;
    display: initial;
  }
  ol {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
    margin-top: 30px;
    list-style-position: outside;
  }
  li {
    display: block;
    margin-bottom: 30px;
    margin-left: 3em;
    position: relative;
    line-height: 21px;
  }
  li::before {
    display: inline-block;
    content: counter(item);
    counter-increment: item;
    font-family: ${headingFamily};
    font-size: 22px;
    width: 40px;
    height: 40px;
    text-align: center;
    font-weight: bold;
    border: 3px solid black;
    border-radius: 3em;
    padding-top: 0.3em;
    margin-top: -8px;
    position: absolute;
    left: -2.5em;
  }
  strong {
    font-size: 22px;
    line-height: 28px;
    font-family: ${headingFamily};
    font-weight: bold;
    display: inline-block;
    margin-bottom: 8px;
  }
  a {
    color: ${colorBlack};
  }
`;

const linkRenderer = (linkOverrides?: LinkOverrides) => {
  const MarkdownLinkElement = (props: React.HTMLProps<HTMLAnchorElement>) => {
    if (!linkOverrides || !props?.href) return <a {...props} />;
    const linkOverride = linkOverrides[props.href];
    if (!linkOverride) {
      return <a {...props} />;
    }
    return (
      <a
        {...props}
        onClick={(event) => {
          event.preventDefault();
          linkOverride();
        }}
      />
    );
  };
  return MarkdownLinkElement;
};

export const Markdown = ({ markdownText, linkOverrides }: MarkdownProps): JSX.Element => {
  const renderers: { [nodeType: string]: ElementType } = {
    link: linkRenderer(linkOverrides),
  };
  return <StyledMarkdown renderers={renderers}>{markdownText}</StyledMarkdown>;
};
