import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProjectStatusMap } from '../../../utils/statusMaps';
import { ProjectsTableProps } from '../types';
import { getProjectStatusDescription } from '../../../utils/projectStatusDescriptions';
import { ProjectStatus } from '../../../services/openapi.types.generated';
import {
  tableColumnProps,
  HeadTr,
  StyledTable,
  TableSection,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  StyledStrong,
  SortHeader,
} from '../styles';
import { Tooltip, Typography } from 'antd';
import { HelpIcon } from '../../HelpIcon';
import styled from 'styled-components';
import { getRoute } from '../utils';

const EllipseText: React.FC<{ text: string | undefined }> = ({ text }) => {
  const [hasEllipse, setHasEllipse] = useState(false);
  return (
    <Tooltip title={hasEllipse ? text : null}>
      <Typography.Text
        ellipsis={{
          onEllipsis: () => {
            setHasEllipse(!hasEllipse);
          },
        }}
        // Below style fixes the broken ellipsis expand for Typography.Text
        style={{ display: 'inline-flex', width: '100%' }}
      >
        {text}
      </Typography.Text>
    </Tooltip>
  );
};

const ProjectStatusTooltip = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ProjectsTable = ({ gridData, ...rest }: ProjectsTableProps): JSX.Element => {
  const history = useHistory();

  return (
    <TableSection>
      <StyledTable>
        <ProjectsTableHeader {...rest} />
        <Tbody>
          {gridData.map((data) => {
            const initiating = data.status === ProjectStatus.Initiating;
            const route = getRoute(data);

            return (
              <Tr
                key={data.id}
                disabled={initiating}
                tabIndex={0}
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (initiating) return;

                  e.preventDefault();
                  if (e.key == 'Enter') {
                    history.push(route);
                  }
                }}
                onClick={(e: React.MouseEvent) => {
                  if (initiating) return;
                  e.preventDefault();
                  history.push(route);
                }}
              >
                <Td {...tableColumnProps['application']}>{data.applicationId || '-'}</Td>
                <Td {...tableColumnProps['reference']}>
                  <EllipseText text={data?.referenceId} />
                </Td>
                <Td {...tableColumnProps['address']}>
                  <EllipseText text={data?.address?.fullAddress} />
                </Td>
                <Td {...tableColumnProps['pathway']}>
                  <EllipseText text={data?.pathway?.name} />
                </Td>
                <Td {...tableColumnProps['completionDate']}>{data.estimatedCompletionDate}</Td>
                <Td {...tableColumnProps['status']}>
                  <ProjectStatusTooltip>
                    <StyledStrong>{ProjectStatusMap[data.status]}</StyledStrong>
                    <HelpIcon style={{ width: '28px', height: '28px' }} id="projectTooltip">
                      {getProjectStatusDescription(data.status)}
                    </HelpIcon>
                  </ProjectStatusTooltip>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </StyledTable>
    </TableSection>
  );
};

export const ProjectsTableHeader: React.FC<Omit<ProjectsTableProps, 'gridData'>> = ({
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
}: Omit<ProjectsTableProps, 'gridData'>) => {
  return (
    <Thead>
      <HeadTr>
        <Th
          onClick={() => {
            if ('applicationId' === sortBy) {
              const newSortOrder = 'asc' === sortOrder ? 'desc' : 'asc';
              setSortOrder(newSortOrder);
              setSortBy('-applicationId');
            } else {
              setSortOrder('asc');
              setSortBy('applicationId');
            }
          }}
          {...tableColumnProps['application']}
        >
          <SortHeader {...tableColumnProps['application']}>Project</SortHeader>
          {'applicationId' === sortBy || '-applicationId' === sortBy ? (
            'asc' === sortOrder ? (
              <i className="fad fa-sort-up"></i>
            ) : (
              <i className="fad fa-sort-down"></i>
            )
          ) : null}
        </Th>
        <Th {...tableColumnProps['reference']}>Your reference</Th>
        <Th {...tableColumnProps['address']}>Site address</Th>
        <Th {...tableColumnProps['pathway']}>Pathway</Th>
        <Th {...tableColumnProps['completionDate']}>Est. completion</Th>
        <Th {...tableColumnProps['status']}>Status</Th>
      </HeadTr>
    </Thead>
  );
};
