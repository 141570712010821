import { Task, TaskPayment } from '../../services/openapi.types.generated';

export type OutstandingPayment = TaskPayment & { status: Exclude<TaskPayment['status'], 'invoiced' | 'paid'> };

export type TaskWithPayment = Task & { payment: NonNullable<Task['payment']> };
export type TaskWithOutstandingPayment = Task & { payment: OutstandingPayment };

/**
 * Determines whether a task has an outstanding payment
 */
export function isTaskPaymentRequired(task: Task): task is TaskWithOutstandingPayment {
  return taskHasPayment(task) && task.requiresPaymentIfAssignedToApproved && isTaskPaymentOutstanding(task.payment);
}
export function isTaskPaymentAllowed(task: Task): task is TaskWithOutstandingPayment {
  return taskHasPayment(task) && task.requiresPaymentIfAssignedToApproved && isTaskPaymentOutstanding(task.payment);
}

export function isTaskStatusComplete(task: Task): ReturnType<typeof isStatusComplete> {
  return isStatusComplete(task.status);
}

export function isTaskStatusPending(task: Task): ReturnType<typeof isStatusPending> {
  return isStatusPending(task.status);
}

export function isTaskAssignedToUser(task: Task): boolean {
  return task.assignedTo === 'user';
}

export function isTaskAssignedToApproved(task: Task): boolean {
  return task.assignedTo === 'approved';
}

export function isTaskService(task: Task): boolean {
  return task.shortDescription === 'Service';
}

export function isTaskDisbursement(task: Task): boolean {
  return task.shortDescription === 'Disbursement';
}

function isStatusComplete(status: Task['status']): status is 'COMPLETE' {
  return status === 'COMPLETE';
}

function isStatusPending(status: Task['status']): status is 'PENDING' {
  return status === 'PENDING';
}

function taskHasPayment(task: Task): task is TaskWithPayment {
  return !!task.payment;
}

function isTaskPaymentOutstanding(taskPayment: TaskPayment): taskPayment is OutstandingPayment {
  return !['invoiced', 'paid'].includes(taskPayment.status);
}
