import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Notification } from '../../../common/notifications';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { Milestone, Project, Task } from '../../../services/openapi.types.generated';
import { FileListItem, FileUpload } from '../../FormControls/FileUpload/FileUpload';

const Container = styled.div`
  ${breakpoint('tablet')`
    text-align: right;
  `}
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colours.red};
  margin: 4px 0;
`;

type FileUploadProps = {
  projectId: string;
  taskId: string;
  setProject?: (project: Project) => void;
  executeRecaptcha: () => Promise<string | null>;
};

const sendUploadNotification = () => {
  Notification(
    'success',
    <span style={{ color: 'white', fontWeight: 'bold' }}>Document Received</span>,
    <>
      <p>The document will be reviewed shortly. If necessary, you can upload further supporting documents.</p>
    </>,
  );
};

export const ProjectFileUpload = ({
  projectId,
  taskId,
  setProject,
  executeRecaptcha,
}: FileUploadProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [{ data: projectResponse, error: uploadError }, executeUploadPost] = useApiRequest<Project, { error: string }>(
    {
      url: `/projects/${projectId}/task/${taskId}/file`,
      method: 'post',
    },
    { manual: true, authRedirect: true },
  );

  useEffect(() => {
    if (setProject && projectResponse) {
      setProject(projectResponse);
      sendUploadNotification();
    }
  }, [setProject, projectResponse]);

  useEffect(() => {
    if (setErrorMessage && uploadError) {
      setErrorMessage(uploadError.response?.data?.error ?? 'Error uploading files');
    }
  }, [uploadError, setErrorMessage]);

  const handleFileUpload = useCallback(
    async (fileList: FileListItem[]) => {
      const fileIdList = fileList.map((file) => file.id);
      if (!fileIdList.some((file) => !file)) {
        const reCaptchaToken = await executeRecaptcha();

        await executeUploadPost({ data: { files: fileIdList, recaptcha: reCaptchaToken } });
      }
    },
    [executeUploadPost],
  );

  return (
    <Container>
      <FileUpload
        onChange={handleFileUpload}
        setErrorMessage={setErrorMessage}
        onLoadingChanged={(bool) => {
          bool;
        }}
        existingFiles={
          projectResponse?.milestones
            ?.flatMap((m: Milestone) => m?.tasks ?? [])
            .find((task: Task) => task?.id === taskId)?.files
        }
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};
