import React from 'react';
import styled from 'styled-components';
import { Heading, Panel } from '../../index';
import { Link } from '../../Link/Link';
import { grayText, colorBlack } from '../../../theme.selectors';
import { ReactComponent as PhoneIcon } from '../../../svg/phone.svg';
import breakpoint from 'styled-components-breakpoint';

type ContactProps = {
  heading: string;
  text: string;
  phoneNumber?: string;
  className?: string;
};

const FaintHeading = styled.div`
  color: ${grayText};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 1em 0;
`;

const FlexLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1.2;
  margin: 0;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
`;

const PhoneDiv = styled.div`
  margin-left: 15px;
  color: ${colorBlack};
`;

const PanelHeading = styled(Heading.H2)`
  font-size: 32px;
  line-height: 36px;
  ${breakpoint('mobile')`
    text-align: center;
  `}
  ${breakpoint('tablet')`
    text-align: inherit;
  `}
`;

export const ContactPanel = ({ heading, text, phoneNumber, className }: ContactProps): JSX.Element => {
  return (
    <Panel className={className}>
      <PanelHeading>{heading}</PanelHeading>
      <FaintHeading>{text}</FaintHeading>
      {phoneNumber && (
        <FlexLink href={`tel:${phoneNumber}`}>
          <PhoneIcon /> <PhoneDiv>{phoneNumber}</PhoneDiv>
        </FlexLink>
      )}
    </Panel>
  );
};
