import React, { useEffect } from 'react';
import { BaseSuccess } from '../../components';
import { WithExpertPanel } from '../../components/Layout/withExperModal.hoc';
import { WithLayout } from '../../components/Layout/withLayout.hoc';
import { WithSubject, WithSubjectProps } from '../../components/Layout/withSubject.hoc';
import { usePageTitle } from '../../context/PageTitleContext';

type InvoiceSuccessProps = WithSubjectProps;
const Component: React.FC<InvoiceSuccessProps> = ({ subject }: InvoiceSuccessProps): JSX.Element => {
  const { setTitle } = usePageTitle();
  useEffect(() => setTitle(`Fee Proposal ${subject?.applicationId}`), [subject]);

  const input = `# What happens next?
   1. Approved will review your documentation and be in touch if further information is required.
   2. Once we have confirmed we have everything we need, we'll start working towards getting your project **Approved**!`;
  return <BaseSuccess title="Thanks!" description="An invoice will be issued soon." markdown={input} />;
};

export const InvoiceSuccess = WithSubject(WithLayout(WithExpertPanel(Component)));
