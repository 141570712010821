import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { LayoutContainer } from '../ContentContainer';

const FooterContainer = styled(LayoutContainer)`
  z-index: 0;
`;

const FooterText = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colours.grayText};
  font-size: 14px;
  position: relative;
`;

const MadeBy = styled.span`
  position: absolute;
  ${breakpoint('desktop')`
    right: 0px;
  `}
  ${breakpoint('tablet')`
    right: 15px;
  `}
  ${breakpoint('mobile')`
    right: 15px;
  `}
`;

const SiteVersionNumber = styled(MadeBy)`
  opacity: 0.3;
  color: ${({ theme }) => theme.colours.black};
  ${breakpoint('desktop')`
    left: 0px;
  `}

  ${breakpoint('tablet')`
    left: 15px;
  `}
  ${breakpoint('mobile')`
    left: 15px;
  `}
`;

const FooterLink = styled(Typography.Link)`
  color: ${({ theme }) => theme.colours.black} !important;
`;

export const Footer = (): JSX.Element => {
  return (
    <FooterContainer>
      <FooterText>
        {process.env.REACT_APP_CLIENT_PORTAL_VERSION && (
          <SiteVersionNumber>
            Version:&nbsp; {process.env.REACT_APP_CLIENT_PORTAL_VERSION.substring(0, 7)}
          </SiteVersionNumber>
        )}
        <MadeBy>
          made by&nbsp;
          <FooterLink href="https://www.digitise.com.au/" target="_blank">
            Digitise
          </FooterLink>
        </MadeBy>
      </FooterText>
    </FooterContainer>
  );
};
