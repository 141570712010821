import { ProjectFormData } from '../components/Form/ProjectForm/config';
import { Address, NewProjectRequest } from './openapi.types.generated';

export const mapProjectRequest = (
  project: ProjectFormData,
  confirmedAddress: Address | undefined | null,
  reCaptchaToken: string | null,
): NewProjectRequest => {
  const {
    address1,
    address2,
    description,
    developmentType,
    clientReference,
    files,
    firstName,
    lastName,
    phone,
    knownPathway,
    owner,
    postcode,
    state,
    suburb,
    value,
  } = project;
  return {
    firstName: firstName ?? null,
    lastName: lastName ?? null,
    phone: phone ?? null,
    address: confirmedAddress
      ? confirmedAddress
      : {
          addressLine1: address1,
          addressLine2: address2,
          postcode,
          state,
          suburb,
        },
    description,
    developmentType,
    clientReference,
    knownPathway,
    owner: owner === 'yes',
    price: value,
    files,
    recaptcha: reCaptchaToken,
  };
};
