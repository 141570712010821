import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { inputFontSize, inputBorderRadius, colorWhite, inputLineHeight } from '../../../theme.selectors';
import { FieldError } from '../index';
import { Heading } from '../../index';
import { ReactComponent as ChevronDown } from '../../../svg/chevron-down.svg';
import { Control, useController } from 'react-hook-form';

type StyledTextProps = {
  error: boolean;
};

type Option = {
  label: string;
  value: string | number | string[];
};
type SelectProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  label: string;
  options: Readonly<Option[]>;
  errors?: string;
  name: string;
  readOnly?: boolean;
};
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledSelect = styled.select<StyledTextProps>`
  box-sizing: border-box;
  height: 36px;
  border-radius: ${inputBorderRadius};
  font-size: ${inputFontSize};
  line-height: ${inputLineHeight};
  background-color: ${colorWhite};
  padding: 9px;
  padding-left: 16px;
  padding-right: 0;
  width: calc(100% - 16px);
  appearance: none;
  box-sizing: content-box;
  border: 1px solid ${({ error, theme }): string => (error ? theme.colours.red : theme.colours.borderColor)};
  ${breakpoint('mobile')`
    max-width: 381px;
  `}
  ${breakpoint('tablet')`
    max-width: 488px;
  `}
  ${breakpoint('desktop')`
    max-width: 576px;
  `};

  &::-ms-expand {
    display: none;
  }
`;
const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
const StyledChevron = styled(ChevronDown)`
  margin-left: -3em;
  pointer-events: none;
`;

export const Select = ({ label, name, options, errors, control, readOnly }: SelectProps): JSX.Element => {
  const {
    field: { onBlur, onChange, ref, value },
    meta: { invalid },
  } = useController({ name, control });

  return (
    <StyledDiv>
      <label htmlFor={name}>
        <Heading.H5>{label}</Heading.H5>
      </label>
      <StyledFlexDiv>
        <StyledSelect
          id={name}
          name={name}
          ref={ref}
          value={value}
          error={invalid}
          onBlur={onBlur}
          onChange={onChange}
          defaultValue=""
          disabled={readOnly}
        >
          <option value="" disabled>
            Select...
          </option>
          {options.map((option) => {
            return (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </StyledSelect>
        <StyledChevron />
      </StyledFlexDiv>
      {errors && <FieldError>{errors}</FieldError>}
    </StyledDiv>
  );
};
