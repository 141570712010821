import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { ReactComponent as PreviousIcon } from '../../svg/pagination-left.svg';
import { ReactComponent as NextIcon } from '../../svg/pagination-right.svg';
import { colorWhite, extraLightGray, lightGray } from '../../theme.selectors';
import { PageObject } from './types';

const PaginateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .pagination {
    padding: 0;
    display: flex;
  }
  &&& {
    .active > a {
      background-color: ${extraLightGray};
    }
  }
  .pagination > li > a:focus {
    outline: none;
  }
  .pagination > li {
    cursor: pointer;
    user-select: none;
    list-style-type: none;
  }
  .pagination > li > a {
    height: 30px;
    width: 30px;
    border: 1px solid ${lightGray};
    border-radius: 4px;
    background-color: ${colorWhite};
    display: inline;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;
    margin-right: 2px;
    font-weight: 500;
  }
  &&& {
    .disabled {
      opacity: 0.3;
    }
    .previous > a,
    .next > a {
      border: 0;
      background-color: transparent;
    }
  }
  .break-me {
    cursor: default;
  }
`;

export type PaginationProps = {
  currentPage: number;
  pageCount: number;
  onPageChange: (page: PageObject) => void;
};

export const Pagination = ({ currentPage, pageCount, onPageChange }: PaginationProps): JSX.Element => {
  return (
    <PaginateContainer>
      <ReactPaginate
        previousLabel={<PreviousIcon />}
        nextLabel={<NextIcon />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        forcePage={currentPage}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </PaginateContainer>
  );
};
