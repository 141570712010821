import { notification } from 'antd';
import React from 'react';

type NotificationMethod = 'success' | 'error' | 'info' | 'warning' | 'open';
const duration = 8;
const style: React.CSSProperties = {
  color: 'white',
  background: 'black',
  fontWeight: 'bold',
  borderRadius: 4,
  opacity: '0.8',
};

export const Notification = (
  method: NotificationMethod,
  message: string | React.ReactNode,
  description?: string | React.ReactNode,
  onClick?: () => void,
): void => {
  notification[method]({
    message,
    description,
    onClick,
    duration,
    style,
    placement: 'top',
  });
};
