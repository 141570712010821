import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLoading } from '../../../context/LoadingContext';

const StyledDiv = styled.div`
  height: 90vh;
`;
/**
 * Generic div to show loading animation on a page rather than individual component
 */
export const LoadingPage = (): JSX.Element => {
  const { setLoading } = useLoading();
  useEffect(() => {
    if (setLoading) {
      setLoading(true);
    }
    return () => {
      if (setLoading) {
        setLoading(false);
      }
    };
  }, []);
  return <StyledDiv></StyledDiv>;
};
