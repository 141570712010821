import React, { useState } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { useSubject } from '../../../hooks/useSubject';
import { Project } from '../../../services/openapi.types.generated';
import { PrimaryButton } from '../../Button';
import { colorBlack, colorWhite } from '../../../theme.selectors';

const DownloadBox = styled(PrimaryButton)`
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colorWhite};
  color: ${colorBlack};
  border: 3px solid ${colorBlack};
  ${breakpoint('tablet')`
    height: 48px;
    width: 250px;
`}
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);

  &:focus,
  &:visited {
    box-shadow: none;
  }

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  }
`;

type Props = {
  subject: Project;
  label?: string;
};

export const DownloadDocumentsButton: React.FC<Props> = (props: Props) => {
  const { subject, label = 'Download Documents' } = props;
  const subjectType = useSubject();
  const [downloading, setDownloading] = useState<boolean>(false);

  const buttonText = downloading ? 'Downloading...' : label;

  const apiRequestUrl = `${subjectType}/${subject.id}`;
  const subjectDocumentsUrl = `${apiRequestUrl}/documents/download`;

  const [{}, downloadDocuments] = useApiRequest(
    {
      method: 'get',
      url: subjectDocumentsUrl,
      responseType: 'arraybuffer',
    },
    {
      manual: true,
      authRedirect: subjectType === 'projects',
    },
  );

  const handleDownload = async () => {
    setDownloading(true);
    const response = await downloadDocuments();
    const buffer = response.data as ArrayBuffer;
    if (buffer && subject) {
      const blob = new Blob([buffer]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Approved-Documents-${subject.applicationId}.zip`;
      link.click();
      setDownloading(false);
    }
  };

  return (
    <DownloadBox trackingId="download-documents" onClick={handleDownload} disabled={downloading}>
      {buttonText}
    </DownloadBox>
  );
};
