import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { colorBlue } from '../../../../theme.selectors';
import { SecondaryButton } from '../../../Button/SecondaryButton';
import { ReactComponent as UploadIcon } from '../../../../svg/upload.svg';

const StyledFileInput = styled.input`
  font-size: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const StyledButton = styled(SecondaryButton)<{ $progress: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.2s;
  background-image: ${({ theme, $progress }) =>
    `linear-gradient(to right, ${theme.colours.lightGray} ${$progress}%, ${theme.colours.white} ${$progress}%)`};

  svg {
    margin-right: 7px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
  &:hover {
    path {
      fill: white;
    }
    ${StyledButton} {
      border: none;
      color: #fff;
      background-color: ${colorBlue};
      background-image: none;
      cursor: pointer;
    }
  }
`;

type UploadButtonProps = {
  className?: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  progress?: number;
};

export const UploadButton = forwardRef<HTMLInputElement, UploadButtonProps>(
  ({ className, label, onChange, progress = 0 }, ref): JSX.Element => (
    <Wrapper className={className}>
      <StyledButton trackingId={'File upload'} $progress={progress}>
        <UploadIcon />
        {label}
      </StyledButton>
      <StyledFileInput
        ref={ref}
        type="file"
        name="fileUpload"
        accept=".jpg, .jpeg, .png, .heic, .pdf, .docx"
        onChange={onChange}
        title={''}
      />
    </Wrapper>
  ),
);

UploadButton.displayName = 'UploadButton';
