import styled from 'styled-components';

import { colorWhite, extraLightGray } from '../../../theme.selectors';

export const Panel = styled.div`
  background: ${colorWhite};
  border: 1px solid ${extraLightGray};
  padding: 21px;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  border-radius: 4px;
  justify-content: space-evenly;
`;
