const emptyString = '' as const;
type EmptyString = typeof emptyString;
type Empty = EmptyString | null | undefined;
type IsEmpty<T> = T extends Empty ? T : never;
type IsEmptyKey<T, K> = T extends IsEmpty<T> ? K : never;
type KeysWithEmptyValues<T> = {
  [K in keyof T]: IsEmptyKey<T[K], K>;
}[keyof T];

function isEmpty(str: unknown): str is EmptyString | null | undefined {
  return str === emptyString || str == null;
}

export function stripEmptyEntries<T extends Record<string, unknown>, R extends Omit<T, KeysWithEmptyValues<T>>>(
  record: T,
): R {
  return Object.entries(record).reduce<R>((memo, [key, value]) => {
    return isEmpty(value) ? memo : { ...memo, [key]: value };
  }, {} as R);
}
