import { AccountInfo, AuthError } from '@azure/msal-common';
import { IMsalContext } from '@azure/msal-react';
import { memoize, sortBy } from 'lodash';
import moment from 'moment';
import { authenticationRequest } from '../components/AuthenticatedRoute';

// get the most recently logged in account id from the msal context
export const getLatestAccount = (msalContext: IMsalContext): AccountInfo | undefined => {
  if (msalContext.accounts.length === 0) {
    return undefined;
  }

  const sortedAccounts = sortBy(
    msalContext.accounts ?? [],
    ({ idTokenClaims }) => (idTokenClaims && idTokenClaims['auth_time']) || 0,
  );

  const account = sortedAccounts[sortedAccounts.length - 1];

  return account;
};

export const _getBearerToken = async (msalContext: IMsalContext): Promise<string | null> => {
  const account = getLatestAccount(msalContext);
  if (!account) {
    throw new AuthError('No account found');
  }
  const authResult = await msalContext.instance.acquireTokenSilent({
    ...authenticationRequest,
    account: account as AccountInfo,
  });
  return authResult.idToken;
};

/**
 * Will create a cache key to reduce the number of times a given function is called over a specified number of seconds
 * @param seconds
 * @returns string
 */
export const tokenCacheResolver = (seconds: number) => (): string => {
  const result = moment(Math.round(moment().valueOf() / (seconds * 1000)) * (seconds * 1000)).format(
    'DD/MM/YYYY hh:mm:ss',
  );
  return result;
};

/**
 * Only call the _getBearerToken method every few seconds
 * Otherwise just return the same result as before
 */
const memoizedBearerToken = memoize(_getBearerToken, tokenCacheResolver(45));

export const getBearerToken = (msalContext: IMsalContext): Promise<string | null> => {
  return memoizedBearerToken(msalContext);
};
