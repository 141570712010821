import React, { useMemo, useState } from 'react';
import type { DefaultOptionType, BaseOptionType } from 'rc-select/lib/Select';
import { Address } from '../../../services/openapi.types.generated';
import { addressMap } from './mapper';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { colorWhite, inputBorderRadius, inputFontSize, inputLineHeight } from '../../../theme.selectors';
import breakpoint from 'styled-components-breakpoint';
import styled, { css } from 'styled-components';
import { Heading } from '../../Heading';
import { ReactComponent as TickIcon } from '../../../svg/tick.svg';

type Props = {
  onChange?: (value: Address) => void;
  value?: Option;
  label: string;
  readOnly?: boolean;
};

export type AddressDetails = {
  PRINCIPALADDRESSSITEOID: number;
  ADDRESSSTRINGOID: number;
  GURASID: number;
  OBJECTID: number;
  CREATEDATE: number;
  GURASID_AS: number;
  ADDRESSTYPE: number;
  RURALADDRESS: number;
  PRINCIPALADDRESSTYPE: number;
  ADDRESSSTRINGTYPE: number;
  PRINCIPALADDRESSSITEOID_AS: number;
  OFFICIALADDRESSSTRINGOID: number;
  ROADSIDE: number;
  HOUSENUMBERFIRSTPREFIX: null | string;
  HOUSENUMBERFIRST: number;
  HOUSENUMBERFIRSTSUFFIX: null | string;
  HOUSENUMBERSECONDPREFIX: null | string;
  HOUSENUMBERSECOND: null | number;
  HOUSENUMBERSECONDSUFFIX: null | string;
  ROADNAME: string;
  ROADTYPE: string;
  ROADSUFFIX: null | string;
  UNITTYPE: null | string;
  UNITNUMBERPREFIX: null | string;
  UNITNUMBER: null | string;
  UNITNUMBERSUFFIX: null | string;
  LEVELTYPE: null | string;
  LEVELNUMBERPREFIX: null | string;
  LEVELNUMBER: null | string;
  LEVELNUMBERSUFFIX: null | string;
  ADDRESSSITENAME: null | string;
  BUILDINGNAME: null | string;
  LOCATIONDESCRIPTION: null | string;
  PRIVATESTREETNAME: null | string;
  PRIVATESTREETTYPE: null | string;
  PRIVATESTREETSUFFIX: null | string;
  SECONDROADNAME: null | string;
  SECONDROADTYPE: null | string;
  SECONDROADSUFFIX: null | string;
  SUBURBNAME: string;
  STATE: number;
  POSTCODE: number;
  COUNCIL: string;
  DELIVERYPOINTID: null | string;
  DELIVERYPOINTBARCODE: null | string;
  ADDRESSCONFIDENCE: null | string;
  CONTRIBUTORORIGIN: number;
  CONTRIBUTORID: string;
  CONTRIBUTORALIGNMENT: number;
  ROUTEOID: null | string;
  GNAFPRIMARYSITEID: number;
  CONTAINMENT: number;
  PROPID: number;
  SPPROPID: null | string;
  ROAD: string;
  ROADSUBURBPOST: string;
  HSDETAILS: string;
  HSNOFRST: string;
  HSNOSECND: string;
  UNITDETAILS: string;
  LVLDETAILS: string;
  ESRI_OID: number;
};

type Option = DefaultOptionType;

type Lookup = Required<Pick<Address, 'id' | 'gurasId' | 'fullAddress'>>;

type PlanningPortalLookup = { address: string; GURASID: string; propId: string };

const StyledAntdSelect = styled(Select)`
  width: 100%;
  &.ant-select {
    & .ant-select-selector {
      border-radius: ${inputBorderRadius};
      height: 56px;
      & input {
        font-size: ${inputFontSize};
        height: 56px !important;
        line-height: 56px;
      }
    }
    & .ant-select-selection-item {
      font-size: ${inputFontSize};
      line-height: 56px;
    }
  }

  // box-sizing: border-box;
  // height: 36px;
  // border-radius: ${inputBorderRadius};
  // font-size: ${inputFontSize};
  // line-height: ${inputLineHeight};
  // background-color: ${colorWhite};
  // padding: 9px;
  // padding-left: 16px;
  // padding-right: 0;
  // width: calc(100% - 16px);
  // appearance: none;
  // box-sizing: content-box;
  // ${breakpoint('mobile')`
  //   max-width: 381px;
  // `}
  // ${breakpoint('tablet')`
  //   max-width: 488px;
  // `}
  // ${breakpoint('desktop')`
  //   max-width: 576px;
  // `};

  // &::-ms-expand {
  //   display: none;
  // }
  // span {
  //   display: block;
  // }
`;
const iconStyle = css`
  height: 18px;
  width: 18px;
`;
const StyledTickIcon = styled(TickIcon)`
  ${iconStyle}
  margin-left: -2.5em;
  margin-top: -3px;
  pointer-events: none;
`;

const StyledDiv = styled.div`
  flex: 80%;
`;
const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const toOptions = (data: Lookup[]): Option[] => {
  return data?.map((lv) => {
    return { label: <div>{lv.fullAddress}</div>, value: lv.id };
  });
};

const getAddressQuery = (propId: string) => {
  const queryParams = {
    f: 'json',
    where: `PROPID=${propId}`,
    outFields: '*',
    returnGeometry: false,
  };
  const baseUrl = 'https://mapprod3.environment.nsw.gov.au/arcgis/rest/services/Common/AddressSearch/MapServer/6/query';
  return [baseUrl, queryParams];
};

const axios = require('axios');

export const getAddressDetails = async (propID: string): Promise<{ attributes: AddressDetails }[] | undefined> => {
  try {
    const [baseUrl, queryParams] = getAddressQuery(propID);
    const response = await axios.get(baseUrl, { params: queryParams });
    const data = response.data;

    if (data.features) {
      return data.features;
    }
  } catch (error) {
    console.error(error);
  }
};

export const AddressSearch: React.FC<Props> = (props: Props) => {
  const { onChange, value, label, readOnly } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [lookupValues, setLookupValues] = useState<Lookup[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [address, setAddress] = useState<Address | null>();

  const search = (input: string) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_ADDRESS_LOOKUP_ENDPOINT}?a=${input}&noOfRecords=${process.env.REACT_APP_ADDRESS_LOOKUP_PAGE_SIZE}`;

    fetch(url)
      .then((response) => response.json())
      .then((searchResults: PlanningPortalLookup[]) => {
        const result = searchResults.map((result) => ({
          fullAddress: result.address,
          id: result.propId,
          gurasId: result.GURASID,
          planningPortalId: result.propId,
        }));

        setLookupValues(result);
        setIsLoading(false);

        return result;
      });
  };

  const handleChange = async (
    value: unknown,
    option: DefaultOptionType | BaseOptionType | (DefaultOptionType | BaseOptionType)[],
  ) => {
    const optionValue = option as DefaultOptionType;
    setIsLoading(true);
    const selectedLookup = lookupValues.find((lookupValue) => lookupValue.id === optionValue.value) as Lookup;
    const newQueryResult = await getAddressDetails(selectedLookup.id);
    if (!newQueryResult) {
      onChange && onChange({} as Address);
      setAddress(null);
      setIsLoading(false);
      return;
    }
    const newAddressDetails = newQueryResult[0].attributes;

    const addressData = addressMap(newAddressDetails, selectedLookup.gurasId, selectedLookup.id);

    onChange && onChange(addressData);
    setIsLoading(false);
    setAddress(addressData);
    return;
  };

  const debouncedSearch: (value: string) => void = useMemo(() => debounce(search, 400), []);

  const placeHolderText = <span style={{ position: 'relative', top: 12 }}>{readOnly && 'Enter address below...'}</span>;

  return (
    <StyledDiv>
      <label htmlFor={'addressSearch'}>
        <Heading.H5>{label}</Heading.H5>
      </label>
      <StyledFlexDiv>
        <StyledAntdSelect
          id="addressSearch"
          showSearch
          disabled={readOnly}
          placeholder={placeHolderText}
          defaultActiveFirstOption={false}
          searchValue={searchValue}
          showArrow={true}
          filterOption={false}
          value={value}
          onSearch={(value) => {
            debouncedSearch(value);
            setSearchValue(value);
          }}
          onChange={handleChange}
          options={toOptions(lookupValues)}
          suffixIcon={isLoading ? <Spin size="small" /> : address && !readOnly ? <StyledTickIcon /> : <></>}
        />
      </StyledFlexDiv>
    </StyledDiv>
  );
};
