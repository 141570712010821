export const formatSize = (size: number): string => {
  if (size < 1000) {
    return size + 'b';
  } else if (size < 1000000) {
    return Math.floor(size / 1000) + 'kb';
  } else {
    return Math.floor(size / 1000000) + 'mb';
  }
};

export const addComma = (amount: number): string => {
  const parts = amount.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};
