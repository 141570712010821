import React from 'react';
import { TaskStatusTextOption } from '../components/Task/ActionTask/TaskStatus';

export const clientStatusDescription: Record<TaskStatusTextOption, (string | JSX.Element)[] | string> = {
  ['Ready for upload']: 'Please upload your relevant documentation.',
  ['Pending']: 'Accept proposal to commence work.',
  ['Scheduled for review']: [
    'Your uploaded documents are scheduled for review by the Approved team.',
    <br key="tooltip" />,
    'If necessary, you can upload further documents to this task for review.',
  ],
  ['Awaiting information']: 'Further information or clarification is required. Check your emails for instructions.',
  ['Completed']: 'Your document has been accepted by Approved.',
  ['Cancelled']: 'This task has been cancelled.',
  ['Not required']: 'This task is no longer required.',
  ['In progress']: 'Please upload your relevant documentation.',
  ['Status unknown']: [
    'There is something wrong with this task.',
    'Contact your Approved expert to resolve the issue.',
  ],
};

export const approvedStatusDescription: Record<TaskStatusTextOption, (string | JSX.Element)[] | string> = {
  ['Pending']: 'Accept proposal to commence work.',
  ['In progress']: 'The Approved team is working on this task.',
  ['Awaiting information']: "Further information or clarification is required. We'll be in touch.",
  ['Completed']: '',
  ['Cancelled']: '',
  ['Not required']: '',
  ['Ready for upload']: 'Please upload your relevant documentation.',
  ['Scheduled for review']: [
    'Your uploaded documents are scheduled for review by the Approved team.',
    <br key="tooltip" />,
    'If necessary, you can upload further documents to this task for review.',
  ],
  ['Status unknown']: [
    'There is something wrong with this task.',
    'Contact your Approved expert to resolve the issue.',
  ],
};
