import styled from 'styled-components';

/**
 * A copy component used for paragraphs on the page.
 * Has breakpoints for mobile and phone view.
 */
export const Copy = styled.p`
  width: 100%;
  font-size: 18px;
  line-height: 21px;
`;
