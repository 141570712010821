import React from 'react';
import { Notification } from '../../common/notifications';

export const requestStatementFailed = (): void => {
  Notification(
    'error',
    <span style={{ color: 'white', fontWeight: 'bold' }}>Failed to send statement request.</span>,
    <p>Please try again later.</p>,
  );
};

export const requestStatementSucceeded = (): void => {
  Notification('success', <span style={{ color: 'white', fontWeight: 'bold' }}>Statement request has been sent.</span>);
};
