import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { px, py } from 'styled-components-spacing';
import { primaryGray } from '../../theme.selectors';

export const ContentContainer = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
`;

export const LayoutContainer = styled(ContentContainer)`
  z-index: 1;
  background-color: ${primaryGray};
  ${py({ mobile: 6, desktop: 7 })}
  ${px({ desktop: 7 })}

  ${breakpoint('desktop')`
    border-radius: 4px;
  `}
`;
