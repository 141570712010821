import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { Copy, Heading, Layout, Markdown } from '../../components';
import Desktop1xJpg from '../../images/quote-form-hero/desktop-1x.jpg';
import Desktop2xJpg from '../../images/quote-form-hero/desktop-2x.jpg';
import Mobile1xJpg from '../../images/quote-form-hero/mobile-1x.jpg';
import Mobile2xJpg from '../../images/quote-form-hero/mobile-2x.jpg';
import Tablet1xJpg from '../../images/quote-form-hero/tablet-1x.jpg';
import Tablet2xJpg from '../../images/quote-form-hero/tablet-2x.jpg';
import { ReactComponent as TickIcon } from '../../svg/tick-circle.svg';

const StyledSecondHeading = styled(Heading.H2)`
  ${breakpoint('mobile')`
  font-size: 36px;
  line-height: 36px;
  `}
  ${breakpoint('tablet')`
  font-size: 48px;
  line-height: 36px;
  `}
  ${breakpoint('desktop')`
  font-size: 48px;
  line-height: 60px;
  `}
`;
const StyledTick = styled(TickIcon)`
  rect {
    fill: transparent;
  }
  g {
    fill: white;
  }
  ${breakpoint('mobile')`
  width: 40px;
  height: 40px;
  `}
  ${breakpoint('tablet')`
  width: 50px;
  height: 50px;
  `}
  ${breakpoint('desktop')`
  width: 60px;
  height: 60px;
  `}
`;
const DescCopy = styled(Copy)`
  margin-top: 15px;
`;
export const QuoteConfirmation = (): JSX.Element => {
  const heading = (
    <div>
      {' '}
      <StyledTick /> Fee Proposal request sent
    </div>
  );
  const input = `# What happens next?

  1. A quote will be prepared and sent to you within one business day. We'll contact you if any further information is needed.
  2. Upon confirmation, we'll get in touch to discuss the next steps.`;

  return (
    <Layout
      mobile={Mobile1xJpg}
      mobile2x={Mobile2xJpg}
      tablet={Tablet1xJpg}
      tablet2x={Tablet2xJpg}
      desktop={Desktop1xJpg}
      desktop2x={Desktop2xJpg}
      heading={heading}
    >
      <StyledSecondHeading>Thanks!</StyledSecondHeading>
      <br />
      <Copy>We&apos;ve sent you a confirmation email.</Copy>
      <DescCopy>An approved team member will be in contact with you shortly.</DescCopy>
      <Margin top={{ mobile: 5, tablet: 6, desktop: 7 }} bottom={{ mobile: 6, tablet: 7, desktop: 8 }}>
        {' '}
        <Markdown markdownText={input} />
      </Margin>
    </Layout>
  );
};
