import React, { useRef, useState } from 'react';
import { Margin } from 'styled-components-spacing';
import { UploadedFile } from '../../../../services/openapi.types.generated';
import { FileBox } from '../FileBox';
import { UploadButton } from '../UploadButton';

const maxFileSizeMB: number = parseInt(process.env.REACT_APP_MAX_FILE_SIZE ?? '100');

export type FileListItem = { file: File; isUploaded?: boolean; id?: string; isError?: boolean };
type FileUploadProps = {
  onChange: (fileList: FileListItem[]) => Promise<void>;
  onLoadingChanged: (isLoading: boolean) => void;
  requestCaptcha?: () => Promise<string | null>;
  existingFiles?: UploadedFile[];
  setErrorMessage: (val: string | null) => void;
};

export const FileUpload = ({
  onChange,
  onLoadingChanged,
  requestCaptcha,
  existingFiles,
  setErrorMessage,
}: FileUploadProps): JSX.Element => {
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<FileListItem[]>([]);

  const removeFileFromArray = (fileToRemove: FileListItem) => {
    const newFileList = fileList.filter((file) => file != fileToRemove);
    setFileList(newFileList);
    updateParentFileList(newFileList);
    updateParentLoadingChanged(newFileList);
  };

  const setFileCompleted = async (file: FileListItem, success: boolean, id?: string) => {
    file.isUploaded = true;
    file.isError = !success;
    file.id = id;

    updateParentLoadingChanged(fileList);
    return await updateParentFileList(fileList);
  };

  const mapFileToFileListItem = (file: File): FileListItem => ({ file });

  const updateParentLoadingChanged = (fileList: FileListItem[]) => {
    if (!fileList.length) {
      onLoadingChanged(false);
      return;
    }
    const isLoading = fileList.some((file) => !file.isUploaded);
    onLoadingChanged(isLoading);
  };

  const updateParentFileList = async (fileList: FileListItem[]) => {
    return await onChange(fileList);
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorMessage(null);
    const files = Array.from(event.target.files ?? []);

    if (!files || files?.length == 0) {
      setErrorMessage('No file selected.');
      return;
    }
    // MB * bytes conversion
    if (files.some((file) => file.size >= maxFileSizeMB * 1048576)) {
      setErrorMessage(`File cannot be larger than ${maxFileSizeMB}MB.`);
      return;
    }

    const updatedFileList: FileListItem[] = [...fileList, ...files.map(mapFileToFileListItem)];
    if (!!requestCaptcha) {
      const recaptchaResult = await requestCaptcha();
      setRecaptcha(recaptchaResult);
    }

    setFileList(updatedFileList);
    updateParentFileList(updatedFileList);
    updateParentLoadingChanged(updatedFileList);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <UploadButton label="Upload a file" onChange={handleFileInputChange} ref={fileInputRef} />

      {fileList?.length ? (
        <Margin top={{ mobile: 2, tablet: 3, desktop: 5 }}>
          {fileList.map(
            (file, index) =>
              file &&
              !existingFiles?.some((projFile) => projFile.id === file.id) && (
                <FileBox
                  key={file.file.name + index}
                  file={file.file}
                  recaptcha={recaptcha}
                  onCancel={() => removeFileFromArray(file)}
                  onComplete={async (success, id) => await setFileCompleted(file, success, id)}
                />
              ),
          )}
        </Margin>
      ) : null}
    </>
  );
};
