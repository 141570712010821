import React, { useState } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ModalProvider } from 'react-modal-hook';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import { GlobalStyle } from './globalStyles/styles';
import { msalConfig } from './msalConfig';
import { DashboardPage } from './pages/Dashboard';
import { ProjectPage } from './pages/Project';
import { ProjectRequestPage } from './pages/ProjectRequest';
import { ErrorPage } from './pages/ServerError';
import { reactPlugin } from './services/appInsights';
import { theme } from './theme';
import { LoadingContext } from './context/LoadingContext';
import { ProjectConfirmation } from './pages/ProjectRequest/confirmation';
import { QuoteConfirmation } from './pages/QuoteRequest/confirmation';
import { StyledModal } from './components/StyledModal';
import { Footer } from './components/Footer';
import { AlreadyAccepted } from './pages/AlreadyAccepted';
import { QuoteScreen } from './pages/Quote';
import { InvoiceSuccess } from './pages/InvoiceSuccess';
import { PaymentSuccess } from './pages/PaymentSuccess';
import { Loading } from './components/Loading';

const pca = new PublicClientApplication(msalConfig);

const AddContext: <TProps>(component: React.FC<TProps>) => React.FC<TProps> = (Component) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [loadingLock, setLoadingLock] = useState(false);

    return (
      <LoadingContext.Provider
        value={{
          loading,
          loadingLock,
          setLoading: (value) => {
            setLoading(value);
          },
          setLoadingLock: (value) => {
            setLoadingLock(value);
            setLoading(value);
          },
        }}
      >
        <MsalProvider instance={pca}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <AppInsightsContext.Provider value={reactPlugin}>
              <ModalProvider>
                <Component {...props} />
                {!loading && !loadingLock && <Footer />}
              </ModalProvider>
            </AppInsightsContext.Provider>
          </ThemeProvider>
        </MsalProvider>
        <StyledModal isOpen={loadingLock} style={{ overlay: { zIndex: 5 } }}>
          <Loading />
        </StyledModal>
      </LoadingContext.Provider>
    );
  };

  return result;
};

const AuthContextRoute = AddContext(AuthenticatedRoute);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ContextRoute = (AddContext(Route as any) as unknown) as typeof Route;

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Switch>
          <AuthContextRoute exact path={`/quotes/:subjectId/accept`} component={QuoteScreen} />
          <AuthContextRoute exact path={`/quotes/:subjectId/invoiced`} component={InvoiceSuccess} />
          <AuthContextRoute exact path={`/quotes/:subjectId/paid`} component={PaymentSuccess} />
          <AuthContextRoute exact path={`/quotes/:subjectId/accepted`} component={AlreadyAccepted} />
          <AuthContextRoute exact path="/projects/:subjectId" component={ProjectPage} />
          <AuthContextRoute exact path={'/projects'} component={ProjectRequestPage} />
          <ContextRoute exact path={`/quotes/:subjectId`} component={QuoteScreen} />
          <ContextRoute exact path="/project-requested" component={ProjectConfirmation} />
          <ContextRoute exact path="/quote-requested" component={QuoteConfirmation} />
          <AuthContextRoute exact path={['/dashboard', '/']} component={DashboardPage} />
          <ContextRoute path="/error" component={ErrorPage} />
          <ContextRoute path="*" component={ErrorPage} />
        </Switch>
      </Router>
    </>
  );
};
// eslint-disable-next-line import/no-default-export
export default App;
