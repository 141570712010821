import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Margin, Padding } from 'styled-components-spacing';
import { useLoading } from '../../../context/LoadingContext';
import { useApiRequest, useErrorRedirect } from '../../../hooks/useApiRequest';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { Quote, Task } from '../../../services/openapi.types.generated';
import { colorBlack, colorWhite, grayText } from '../../../theme.selectors';
import { CheckList, Copy, Divider, Heading, MilestoneHeader, PaymentTotal } from '../../index';
import { isTaskAssignedToApproved, isTaskAssignedToUser, isTaskService, isTaskDisbursement } from '../task-logic';
import { InvoiceButton } from '../InvoiceSection';
import { AxiosError } from 'axios';
import { PaymentStatus } from '../../Form/PaymentForm/payment-status';
import { usePageTitle } from '../../../context/PageTitleContext';
import { addressInfo } from '../../../utils/addressInfo';
import { notification, Typography } from 'antd';
import { ApprovedPhoneNumber } from '../../Form/constants';
import { ReactComponent as PhoneIcon } from '../../../svg/phone.svg';
import { ButtonWrapper, StyledPrimaryButton } from '../../Form/PaymentForm/types';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { PaymentForm } from '../../Form/PaymentForm';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Subject } from '../../../hooks/useSubject';
import { WithSubjectProps } from '../../Layout/withSubject.hoc';

const PaymentTasksSection = styled.div``;
const UserAssignedTasks = styled.div``;
const StripeSection = styled(Padding)``;
const StyledHeading = styled.span`
  color: ${grayText};
`;
const Description = styled(Copy)`
  font-size: 16px;
  margin-bottom: 24px;
  margin-top: 16px;
`;

const EmptyProposalText = styled.div`
  text-align: center;
  width: 100%;
`;
const EmptyProposalPhone = styled(Typography.Link)`
  font-weight: 500;
  color: black !important;
`;

const SectionHeading = styled.h4`
  background: ${colorBlack};
  color: ${colorWhite};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.125rem;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  height: 24px;
  width: 24px;
`;

const StyledInvoiceButton = styled(InvoiceButton)`
  text-align: right;
`;

const PaymentActionWrapper = styled(Margin)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

type PaymentSectionProps = Omit<WithSubjectProps, 'subject'> & {
  subject: Subject;
  tasks: Task[];
  onSuccessfulInvoice: (isSuccessful: boolean) => void;
  onPayment: (status: PaymentStatus) => void;
};
export const PaymentSection = ({
  subject,
  setSubject,
  tasks,
  onPayment,
  onSuccessfulInvoice,
}: PaymentSectionProps): JSX.Element => {
  const {
    id,
    validFrom,
    milestones,
    applicationId: referenceId,
    totalGST: gst,
    totalPrice: total,
    subTotalPrice: subtotal,
  } = subject;

  const [currentTasks, setCurrentTasks] = useState<Task[]>(tasks);
  const [recalculatingIntent, setIfRecalculatingIntent] = useState<boolean>(false);
  const [invoiceError, setInvoiceError] = useState<AxiosError | undefined>(undefined);
  const { setTitle } = usePageTitle();

  useEffect(() => setTitle('Fee proposal'), []);
  const history = useHistory();

  const { setLoadingLock } = useLoading();
  useScrollToTop();

  useEffect(() => setCurrentTasks(tasks), [tasks]);

  const userTasks = currentTasks.filter((action) => isTaskAssignedToUser(action));
  const userServices = userTasks.filter(isTaskService);
  const userDisbursements = userTasks.filter(isTaskDisbursement);

  const approvedTasks = currentTasks.filter((action) => isTaskAssignedToApproved(action));
  const approvedServices = approvedTasks.filter(isTaskService);
  const approvedDisbursements = approvedTasks.filter(isTaskDisbursement);

  const [{ data, loading, error }, executeAssignTasks] = useApiRequest<Quote>(
    {
      url: `/quotes/${id}/assign-tasks`,
      method: 'patch',
    },
    { manual: true, authRedirect: false },
  );

  const conflictError = error?.response?.status === 409 || error?.response?.statusText === 'Conflict';

  useEffect(() => {
    if (conflictError) {
      const message = error?.response?.data?.error || error?.response?.data?.message;

      notification.warning({
        message: message ?? 'Conflict error: please refresh and try again.',
        onClick: () => {
          location.reload();
        },
        description: 'Click here to refresh.',
        duration: 10,
        placement: 'topLeft',
        top: 90,
      });
    }
  }, [error]);

  useEffect(() => data && setSubject && setSubject(data as Subject), [data]);

  useEffect(() => {
    setLoadingLock(loading);
    setIfRecalculatingIntent(loading);
  }, [loading]);

  useErrorRedirect([!conflictError && error, invoiceError]);

  const handleSubmit = async (currentTasks: Task[]) => {
    setIfRecalculatingIntent(true);
    const tasks = currentTasks.map((task) => {
      return {
        id: task.id,
        assignedTo: task.assignedTo || 'user',
      };
    });

    await executeAssignTasks({ data: { tasks, validFrom } });
  };

  const handleChangeAssignee = async (newTask: Task) => {
    const filteredTasks = currentTasks.filter((task: Task) => task.id != newTask.id);
    const newCurrentTasks = [...filteredTasks, { ...newTask }];
    setCurrentTasks(newCurrentTasks);
    await handleSubmit(newCurrentTasks);
  };

  const invoiceSuccessful = () => {
    onSuccessfulInvoice(true);
  };

  const currentMilestone = (milestones ?? []).find((m) => m.status === 'in-progress');

  const skipPayment = !!subject.isInvoiced;
  const expertPhone = subject.assignedExpert?.phone ?? ApprovedPhoneNumber;
  const nothingToPay = total === '$0.00';
  const shouldShowPaymentForm = !nothingToPay && !recalculatingIntent && !skipPayment;
  const shouldContactApproved = !skipPayment && !shouldShowPaymentForm && !recalculatingIntent;

  return (
    <>
      <Margin>
        <Heading.H2>{currentMilestone?.title ?? ''}</Heading.H2>
        <StyledHeading>
          {addressInfo(subject)}
          <br />
          Reference: {referenceId ? referenceId : 'N/A'}
        </StyledHeading>
        <Margin style={{ textAlign: 'center' }}>
          <MilestoneHeader subject={subject} />
        </Margin>
      </Margin>
      {(userServices.length > 0 || userDisbursements.length > 0) && (
        <Margin>
          <Heading.H3>Assigned to you</Heading.H3>
          <Description>
            If you would like us to handle these for you, click &nbsp;&apos;Assign to Approved&apos;.
          </Description>
          <Margin vertical={4}>
            <Divider />
          </Margin>
          <UserAssignedTasks>
            {userServices.length > 0 && (
              <>
                <Margin bottom={3}>
                  <SectionHeading>Services</SectionHeading>
                </Margin>
                <CheckList tasks={userServices} onCheckboxClick={(newTask: Task) => handleChangeAssignee(newTask)} />
              </>
            )}
            {userDisbursements.length > 0 && (
              <>
                <Margin bottom={3}>
                  <SectionHeading>Disbursements</SectionHeading>
                </Margin>
                <CheckList
                  tasks={userDisbursements}
                  onCheckboxClick={(newTask: Task) => handleChangeAssignee(newTask)}
                />
              </>
            )}
          </UserAssignedTasks>
        </Margin>
      )}

      {!nothingToPay ? (
        <Margin>
          <Heading.H3>Assigned to Approved </Heading.H3>
          <Margin vertical={4}>
            <Divider />
          </Margin>
        </Margin>
      ) : null}
      <PaymentTasksSection>
        {approvedServices.length > 0 && (
          <>
            <Margin bottom={3}>
              <SectionHeading>Services</SectionHeading>
            </Margin>
            <CheckList tasks={approvedServices} onCheckboxClick={(newTask) => handleChangeAssignee(newTask)} />
          </>
        )}
        {approvedDisbursements.length > 0 && (
          <>
            <Margin bottom={3}>
              <SectionHeading>Disbursements</SectionHeading>
            </Margin>
            <CheckList tasks={approvedDisbursements} onCheckboxClick={(newTask) => handleChangeAssignee(newTask)} />
          </>
        )}

        {!nothingToPay && (
          <StripeSection>
            <PaymentTotal showDividers={false} total={total} gst={gst} subtotal={subtotal} />
          </StripeSection>
        )}

        <PaymentActionWrapper top={6}>
          {shouldContactApproved ? (
            <EmptyProposalText>
              This fee proposal has <span style={{ textDecoration: 'underline' }}>no tasks assigned to approved</span>.{' '}
              <br />
              Please call your approved expert at{' '}
              <EmptyProposalPhone href={`tel:${expertPhone}`}>
                <StyledPhoneIcon /> {expertPhone}
              </EmptyProposalPhone>{' '}
              to confirm the proposed works.
            </EmptyProposalText>
          ) : (
            <>
              <Route exact path={`/quotes/${id}`}>
                <AuthenticatedTemplate>
                  <Redirect to={`/quotes/${id}/accept`} />
                </AuthenticatedTemplate>
                <ButtonWrapper>
                  <span id="payProposal">
                    <StyledPrimaryButton
                      trackingId={'login-and-pay'}
                      buttonType="submit"
                      onClick={() => history.replace(`/quotes/${id}/accept`)}
                    >
                      {skipPayment ? 'Login to Accept' : 'Login to Pay'}
                    </StyledPrimaryButton>
                  </span>
                </ButtonWrapper>
              </Route>
              <Route exact path={`/quotes/${id}/accept`}>
                {skipPayment ? (
                  <StyledInvoiceButton
                    subjectId={id}
                    setData={invoiceSuccessful}
                    setError={setInvoiceError}
                    setLoading={(loading) => setLoadingLock && setLoadingLock(loading)}
                    validFrom={validFrom}
                  />
                ) : (
                  <PaymentForm subject={subject} onPayment={onPayment} isDisabled={total === '$0.00'} />
                )}
              </Route>
            </>
          )}
        </PaymentActionWrapper>
      </PaymentTasksSection>
    </>
  );
};
