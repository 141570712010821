import { AuthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { px, py } from 'styled-components-spacing';
import { ReactComponent as LeftIcon } from '../../svg/chevron-left.svg';
import { colorBlack, colorWhite } from '../../theme.selectors';
import { BackgroundImage } from '../BackgroundImage';
import { ContentContainer as Container } from '../ContentContainer';
import { Heading } from '../Heading';
import { RouterLink } from '../Link/RouterLink';

type HeroProps = {
  children: React.ReactNode;
  mobile: string;
  mobile2x: string;
  tablet: string;
  tablet2x: string;
  desktop: string;
  desktop2x: string;
  showBackButton?: boolean;
};

const HeroWrapper = styled.div`
  position: relative;
  ${px({ mobile: 4, tablet: 5 })}

  ${breakpoint('mobile')`
    height: 220px;
  `}

  ${breakpoint('tablet')`
    height: 300px;
  `}

  ${breakpoint('desktop')`
    height: 385px;
  `}
`;

const HeroBackgroundImage = styled(BackgroundImage)`
  background-color: ${colorBlack};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  ${breakpoint('desktop')`
    bottom: -150px;
  `}

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: ${colorBlack};
    opacity: 0.5;
  }
`;

const ContentContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${py({ mobile: 4, tablet: 5 })};
`;

const HeroLink = styled(RouterLink)`
  color: ${colorWhite};
  display: flex;

  > *:not(:last-child) {
    margin-right: 0.5em;
  }
`;

const HeroHeading = styled(Heading.H1)`
  color: ${colorWhite};
  margin-top: auto;
`;

const StyledBackButton = styled(LeftIcon)`
  width: 24px;
  height: 24px;
`;

export const Hero = ({
  children,
  mobile,
  mobile2x,
  tablet,
  tablet2x,
  desktop,
  desktop2x,
  showBackButton = true,
}: HeroProps): JSX.Element => {
  return (
    <HeroWrapper>
      <HeroBackgroundImage
        id="hero"
        mobile={mobile}
        mobile2x={mobile2x}
        tablet={tablet}
        tablet2x={tablet2x}
        desktop={desktop}
        desktop2x={desktop2x}
      />
      <ContentContainer>
        <AuthenticatedTemplate>
          {showBackButton && (
            <HeroLink to="/" trackingId="track-this-link">
              <StyledBackButton /> <span>Your Projects</span>
            </HeroLink>
          )}
        </AuthenticatedTemplate>
        <HeroHeading>{children}</HeroHeading>
      </ContentContainer>
    </HeroWrapper>
  );
};
