import { useMsal } from '@azure/msal-react';
import { Button, Divider } from 'antd';
import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { useLoading } from '../../context/LoadingContext';
import { ReactComponent as PlusIcon } from '../../svg/burger-plus.svg';
import { ReactComponent as ExitIcon } from '../../svg/sign-out-regular.svg';
import { ReactComponent as ContainerIcon } from '../../svg/container.svg';
import { bodyFamily, colorBlack, colorWhite } from '../../theme.selectors';
import { useApiRequest } from '../../hooks/useApiRequest';
import { requestStatementFailed, requestStatementSucceeded } from '../Menu';
import { getLatestAccount } from '../../services/authentication';

const StyledBurgerIcon = styled.div`
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 20px;
    color: white;
    transition: color 0.2s;
    font-family: ${bodyFamily};
    cursor: pointer;
  }
  .bm-item:hover {
    opacity: 0.6;
  }
  .bm-item:hover:first-child {
    cursor: auto;
    opacity: 1;
  }
  /* Change color on hover */
  .bm-item:hover {
    color: ${colorWhite};
  }
  .bm-burger-bars {
    height: 3px !important;
    width: 24px !important;
  }
  .bm-burger-bars:nth-of-type(2) {
    top: 9px !important;
  }
  .bm-burger-bars:nth-of-type(3) {
    top: 18px !important;
  }
  /* The rest copied directly from react-burger-menu docs */

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: absolute;
    width: 28px;
    height: 24px;
    right: 16px;
    top: 22px;
    z-index: 1;
  }
  #react-burger-menu-btn {
    z-index: 3 !important;
  }
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    z-index: 2;
    background: ${colorWhite};
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    transform: scale(1.5);
  }
  .bm-cross-button:hover {
    opacity: 0.6;
  }
  /* Color/shape of close button cross */
  .bm-cross {
    background: ${colorWhite};
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${colorBlack};
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  display: block;
  ${breakpoint('tablet')`
    display: none;
  `}
`;
const baseIconStyle = css`
  color: ${colorWhite};
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;
const StyledPlus = styled(PlusIcon)`
  ${baseIconStyle}
`;
const ContainerOutlined = styled(ContainerIcon)`
  ${baseIconStyle}
`;
const StyledSignOut = styled(ExitIcon)`
  ${baseIconStyle}
  margin-left: 10px;
  float: right;
`;
const UsernameContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`;
const BurgerButton = styled(Button)``;
const BurgerButtonLogout = styled(Button)`
  float: right;
`;

type BurgerMenuProps = {
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
};

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ isOpen, setIsOpen }): JSX.Element => {
  const msalContext = useMsal();
  const history = useHistory();
  const { setLoadingLock } = useLoading();
  const handleLogoutClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setLoadingLock(true);
    msalContext.instance.logout();
  };
  const handleLogoutPress = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.key == 'Enter') {
      setLoadingLock(true);
      msalContext.instance.logout();
    }
  };

  const [{ loading: statementIsLoading }, sendEmail] = useApiRequest(
    {
      method: 'POST',
      url: '/emails/statement-request',
    },
    { manual: true, authRedirect: true },
  );

  const handleRequestStatement = async () => {
    try {
      await sendEmail();

      requestStatementSucceeded();
    } catch (error) {
      requestStatementFailed();
    } finally {
      if (isOpen) setIsOpen(false);
    }
  };

  // Statement request feature END

  return (
    <StyledBurgerIcon onClick={() => setIsOpen(true)}>
      <Menu isOpen={isOpen} pageWrapId={'page-wrap'} outerContainerId={'App'} right disableAutoFocus>
        <UsernameContainer>
          Signed in as <br />
          {getLatestAccount(msalContext)?.username ?? 'Unknown user'}
        </UsernameContainer>
        <Divider />

        <BurgerButton
          id="newProject"
          type="text"
          tabIndex={0}
          onKeyPress={(e: React.KeyboardEvent) => {
            e.preventDefault();
            if (e.key == 'Enter') {
              history.push(`/projects`);
            }
          }}
          onClick={() => {
            history.push(`/projects`);
          }}
          icon={<StyledPlus />}
        >
          Start new project
        </BurgerButton>

        <BurgerButton
          id="requestStatement"
          type="text"
          tabIndex={0}
          onClick={handleRequestStatement}
          loading={statementIsLoading}
          icon={<ContainerOutlined />}
        >
          Request a Statement
        </BurgerButton>

        <BurgerButtonLogout
          id="about"
          type="text"
          tabIndex={0}
          onClick={handleLogoutClick}
          onKeyDown={handleLogoutPress}
          icon={<StyledSignOut />}
        >
          Log out
        </BurgerButtonLogout>
      </Menu>
    </StyledBurgerIcon>
  );
};
