import styled, { css } from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { inputBorderRadius, inputFontSize, inputPadding } from '../../../theme.selectors';

type StyledTextProps = {
  error: boolean;
};

const inputStyle = css`
  box-sizing: border-box;
  border-radius: ${inputBorderRadius};
  font-size: ${inputFontSize};
  padding: ${inputPadding};
  width: 100%;
  ${breakpoint('mobile')`
      height: 56px;
    `}
  ${breakpoint('tablet')`
      height: 56px;
    `}
    ${breakpoint('desktop')`
      height: 56px;
    `}
`;

export const StyledInput = styled.input<StyledTextProps>`
  ${inputStyle}
  border: 1px solid ${({ error, theme }): string => (error ? theme.colours.red : theme.colours.borderColor)};
`;
