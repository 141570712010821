import React, { useContext } from 'react';
type LoadingContextProps = {
  loading: boolean;
  loadingLock: boolean;
  setLoading: (loading: boolean) => void;
  setLoadingLock: (loading: boolean) => void;
};

export const LoadingContext = React.createContext<LoadingContextProps>({
  loading: false,
  setLoading: () => {
    return;
  },
  loadingLock: false,
  setLoadingLock: () => {
    return;
  },
});

export const useLoading = (): LoadingContextProps => useContext(LoadingContext);
