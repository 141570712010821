import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { FieldError as ReactHookFormFieldError, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { Copy, FieldError, Heading, Link, RadioButton, Select, TextArea, TextBox, useAlertModal } from '../..';
import { useLoading } from '../../../context/LoadingContext';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { Address, Contact } from '../../../services/openapi.types.generated';
import { mapProjectRequest } from '../../../services/projectRequest';
import { PrimaryButton } from '../../Button';
import { AddressSearch, FileListItem, FileUpload } from '../../FormControls';
import { Switch } from '../../FormControls/Switch';
import { HelpIcon } from '../../HelpIcon';
import {
  ApprovedPhoneNumber,
  developmentTypes,
  knownPathways,
  knownPathwaysForDevelopmentType,
  reCaptchaSiteKey,
  stateOptions,
} from '../constants';
import { ProjectFormData, projectFormDefaultValues, QuoteFormData } from './config';
import { projectRequestSchema, quoteRequestSchema } from './schema';
import { mapQuoteRequest } from '../../../services/quoteRequest';
import ReCAPTCHA from 'react-google-recaptcha';
import { useReCaptcha } from '../../../hooks/useRecaptcha';
import { useSubject } from '../../../hooks/useSubject';
import { Space } from 'antd';

const StyledFlexDiv = styled.div`
  display: flex;
  > * {
    flex: 1 1 50%;
  }
`;
const StyledFlexRadio = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
  > * {
    flex: 1 1 50%;
  }
`;
const StyledDiv = styled.div`
  display: flex;
  space: 10px;
  column-count: 2;
`;
const RadioDiv = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;
const StyledCopy = styled(Copy)`
  font-size: 14px;
  margin-top: 0;
`;
const StyledSection = styled.section`
  margin-top: 3em;
`;
const StyledOptionalSection = styled.section<{ show: boolean }>`
  margin-top: 1.5em;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const StyledRecaptcha = styled(ReCAPTCHA)``;

const StyledRecaptchaDisclaimer = styled.div`
  margin-top: 30px;
  color: ${({ theme }) => theme.colours.grayText};
  font-size: 0.8em;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 3em;
  ${breakpoint('mobile', 'tablet')`
    width: 100%;
  `}
`;

const ValueTextBox = styled(TextBox)`
  input {
    padding-left: 32px;
  }

  position: relative;
  &:before {
    content: '$';
    position: absolute;
    top: 64px;
    left: 16px;
    display: inline-block;
    z-index: 1;
    @media only screen and (max-width: 350px) {
      top: 60px;
    }
    ${breakpoint('mobile')`
      top: 45px;
    `};
    ${breakpoint('tablet')`
      top: 45px;
    `};
  }
`;
/* in order to hide the recaptcha, we have to include their privacy policy and a disclaimer somewhere else on the site...*/
const StyledForm = styled.form`
  padding-bottom: 50px;
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const SmallCopy = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colours.grayText};
`;

export type ProjectFormErrorResponse = {
  validationErrors: { message: string }[];
  requestId?: string;
  error?: string;
  message?: string;
};

export type ProjectFormProps = {
  requestId: string;
  onSubmitted?: (data: ProjectFormData) => void;
};

export const ProjectForm = ({ requestId, onSubmitted }: ProjectFormProps): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const [isUploading, setIsUploading] = useState(false);
  const [manualMode, setManualMode] = useState(false);
  const [confirmedAddress, setConfirmedAddress] = useState<Address | null>(null);
  const [reCAPTCHARef, executeRecaptcha] = useReCaptcha();
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const { setLoadingLock } = useLoading();
  const [showAlertModal] = useAlertModal();

  const subject = useSubject();
  const isProject = subject === 'projects';
  const dtoMapper = isProject ? mapProjectRequest : mapQuoteRequest;
  const submitUrl = isProject ? `/projects/request` : `/quotes/request`;
  const schema = isProject ? projectRequestSchema : quoteRequestSchema;

  const [{ data: contact, loading: contactLoading }] = useApiRequest<Contact>(
    {
      method: 'get',
      url: `/me/contact`,
    },
    {
      authRedirect: true,
    },
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    watch,
    control,
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm<ProjectFormData & QuoteFormData>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    defaultValues: { ...projectFormDefaultValues, requestId },
  });
  const watchKnownPathway = watch('knownPathway');
  const constructionCostCalculatorLink = process.env.REACT_APP_CONSTRUCTION_COST_CALCULATOR_LINK ?? null;

  useEffect(() => {
    register('files');
    register('requestId');
  }, [register]);
  useEffect(() => {
    setValue('requestId', requestId);
  }, [requestId]);

  useEffect(() => {
    setValue('firstName', contact?.firstName);
    setValue('lastName', contact?.lastName);
    setValue('phone', contact?.phone);
  }, [contact]);

  const [{ loading: postLoading, error: postError }, executePost] = useApiRequest<
    ProjectFormData,
    ProjectFormErrorResponse
  >(
    {
      method: 'post',
      url: submitUrl,
    },
    {
      manual: true,
      authRedirect: false,
    },
  );

  useEffect(() => {
    if (setLoadingLock) {
      setLoadingLock(postLoading || contactLoading);
    }
  }, [setLoadingLock, postLoading, contactLoading]);

  useEffect(() => {
    if (postError) {
      let message = undefined;
      let errorCode = undefined;
      if (postError && postError.response) {
        const { response, code } = postError;
        const { status } = response;
        message = response?.data?.error || response?.data?.message;
        errorCode = `${status}` ?? code;
      } else {
        message = postError.message;
      }
      if (postError.response?.status === 400 && postError.response?.data?.validationErrors) {
        const { status, statusText, data } = postError?.response;
        try {
          appInsights.trackException(
            { id: data?.requestId, exception: postError, severityLevel: SeverityLevel.Warning },
            { status, statusText, data },
          );
        } catch {}
        showAlertModal({
          title: `Error Submitting Project`,
          trackingPrefix: 'ProjectRequestErrorValidationAlert',
          description: data.validationErrors.map((error) => error.message).join(', '),
        });
        return;
      }

      try {
        const response = postError?.response;
        appInsights.trackException(
          { id: response?.data?.requestId, exception: postError, severityLevel: SeverityLevel.Critical },
          { status: response?.status, statusText: response?.statusText, data: response?.data },
        );
      } catch {}
      showAlertModal({
        title: `Error Submitting Project`,
        trackingPrefix: 'ProjectRequestErrorValidationAlert',
        description: (
          <>
            {`${message}`}
            <br />
            {`Please try again later or contact us on ${ApprovedPhoneNumber}`}
            <br />
            <SmallCopy>{`Reason - ${errorCode}`}</SmallCopy>
          </>
        ),
      });
    }
  }, [postError, appInsights]);

  const handleFileUploadChange = async (fileList: FileListItem[]) => {
    const mappedFileList = fileList.map((file) => file.id);
    setValue('files', mappedFileList, { shouldDirty: true, shouldValidate: true });
  };

  const updateManualAddressValues = (address: Address | null) => {
    setValue('address1', address?.addressLine1?.toUpperCase() ?? '');
    setValue('address2', address?.addressLine2?.toUpperCase() ?? '');
    setValue('suburb', address?.suburb?.toUpperCase() ?? '');
    setValue('postcode', address?.postcode ?? '');
    setValue('state', address?.state ?? '');
    clearErrors(['address1', 'address2', 'suburb', 'postcode', 'state']);
    // reset everthing except the form values themselves
    reset(getValues(), {
      errors: true,
      isDirty: true,
      isValid: true,
      isSubmitted: true,
      submitCount: true,
      dirtyFields: true,
      touched: false,
    });
    register('requestId');
    setValue('requestId', requestId);
  };

  const handleAddressChange = (address: Address) => {
    setConfirmedAddress(address);
    updateManualAddressValues(address);
  };

  const handleManualChange = (checked: boolean) => {
    setManualMode(checked);
    if (!checked) {
      updateManualAddressValues(confirmedAddress);
    }
  };

  const onSubmit = async (data: ProjectFormData): Promise<void> => {
    try {
      appInsights.trackEvent({ name: 'project-request-form-submission' }, { requestId: data.requestId });
    } catch {}

    const reCaptchaToken = await executeRecaptcha();

    const projectRequest = dtoMapper(data, manualMode ? null : confirmedAddress, reCaptchaToken);

    await executePost({ data: projectRequest });
    onSubmitted && onSubmitted(data);
  };

  type Errors = typeof errors;
  const hasAddressErrors = (errors: Errors) =>
    !!(
      errors.address1?.message ||
      errors.address2?.message ||
      errors.suburb?.message ||
      errors.state?.message ||
      errors.postcode?.message
    );

  const showManualAddress = manualMode || hasAddressErrors(errors);

  useEffect(() => {
    if (hasAddressErrors(errors)) {
      setManualMode(true);
    }
  }, [showManualAddress]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <section style={{ display: !contact?.firstName || !contact?.lastName || !contact?.phone ? 'auto' : 'none' }}>
        <TextBox name="firstName" label="First Name" errors={errors.firstName?.message} control={control} />
        <TextBox name="lastName" label="Last Name" errors={errors.lastName?.message} control={control} />
        <TextBox name="phone" label="Phone" errors={errors.phone?.message} control={control} />
      </section>
      <StyledSection>
        <Heading.H2>Site Address</Heading.H2>
        <StyledCopy>(Where the work is proposed)</StyledCopy>
        <StyledDiv>
          <AddressSearch readOnly={manualMode} onChange={handleAddressChange} label={'Address'} />
        </StyledDiv>
        <Switch onChange={handleManualChange} name="manual" label={'Manual'} showManualAddress={showManualAddress} />

        <div style={(showManualAddress && {}) || { display: 'none' }}>
          <TextBox
            name="address1"
            label="Street Address line 1"
            errors={errors.address1?.message}
            control={control}
            readOnly={!manualMode}
            forceUppercase
          />
          <TextBox
            name="address2"
            label="Street Address line 2"
            errors={errors.address2?.message}
            control={control}
            readOnly={!manualMode}
            forceUppercase
          />
          <TextBox
            name="suburb"
            label="Suburb"
            errors={errors.suburb?.message}
            control={control}
            readOnly={!manualMode}
            forceUppercase
          />
          <StyledFlexDiv>
            <TextBox
              name="postcode"
              label="Post Code"
              errors={errors.postcode?.message}
              maxLength={4}
              control={control}
              readOnly={!manualMode}
            />
            <Margin top={{ mobile: 3, tablet: 4, desktop: 5 }} left={5}>
              <Select
                label="State"
                name="state"
                control={control}
                options={stateOptions}
                errors={errors.state?.message}
                readOnly={!manualMode}
              />
            </Margin>
          </StyledFlexDiv>
        </div>
        <RadioDiv>
          <Heading.H5>Are you the owner of the property at the above address?</Heading.H5>
          <StyledFlexRadio>
            <RadioButton name="owner" label="Yes" id="ownerYes" value="yes" control={control} />
            <RadioButton name="owner" label="No" id="ownerNo" value="no" control={control} />
          </StyledFlexRadio>
        </RadioDiv>
        <TextBox
          name="clientReference"
          label="Your reference"
          labelComponent={
            <Space direction="horizontal">
              <span>Your reference (if applicable)</span>
              <HelpIcon style={{ width: '28px', height: '28px' }} id="valueHelpIcon">
                Optional field for a personal reference that you might likes to appear as part of a quote or invoice.
              </HelpIcon>
            </Space>
          }
          control={control}
          errors={errors.clientReference?.message}
        />
      </StyledSection>
      <StyledSection>
        <Heading.H2>Proposed Works</Heading.H2>

        <TextArea
          name="description"
          label="Provide a brief description of the proposed building works"
          control={control}
          errors={errors.description?.message}
        />

        <ValueTextBox
          name="value"
          label="Estimated cost of proposed works (if known)"
          labelComponent={
            <Space direction="horizontal">
              <span>Estimated cost of proposed works (if known) </span>
              <HelpIcon style={{ width: '28px', height: '28px' }} id="valueHelpIcon">
                The contract price or, if there is no contract, a reasonable estimate for all labour and material costs
                associated with the development.{' '}
                {constructionCostCalculatorLink && (
                  <>
                    Our{' '}
                    <Link href="#" trackingId="quote-request-construction-cost-calculator">
                      Construction Cost Calculator
                    </Link>{' '}
                    can help provide you with an estimate.
                  </>
                )}
              </HelpIcon>
            </Space>
          }
          errors={errors.value?.message}
          control={control}
        />
        {constructionCostCalculatorLink && (
          <p>
            <small>
              Make a more accurate estimate with the&nbsp;
              <Link
                href={constructionCostCalculatorLink}
                trackingId="quote-request-construction-cost-calculator"
                target="_blank"
                style={{ color: 'darkblue', textDecoration: 'underline' }}
              >
                Construction Cost Calculator.
              </Link>
            </small>
          </p>
        )}
      </StyledSection>
      <StyledSection>
        <Heading.H2>Do you know what application or assessment type you require?</Heading.H2>
        {knownPathways.map((knownPathway) => (
          <RadioButton
            key={knownPathway}
            name="knownPathway"
            label={knownPathway}
            id={`knownPathway${knownPathway}`}
            value={knownPathway}
            control={control}
          />
        ))}
        {errors.knownPathway && (
          <Margin top={5} bottom={0}>
            <FieldError>{errors.knownPathway.message}</FieldError>
          </Margin>
        )}

        <StyledOptionalSection show={knownPathwaysForDevelopmentType.includes(watchKnownPathway)}>
          <Heading.H5>You&apos; ve nominated {watchKnownPathway}, please select your development type</Heading.H5>
          {developmentTypes.map((developmentType) => (
            <RadioButton
              key={developmentType}
              name="developmentType"
              label={developmentType}
              id={`developmentType${developmentType}`}
              value={developmentType}
              control={control}
            />
          ))}
          {errors.developmentType && (
            <Margin top={5} bottom={0}>
              <FieldError>{errors.developmentType.message}</FieldError>
            </Margin>
          )}
        </StyledOptionalSection>
      </StyledSection>
      <StyledSection>
        <Heading.H2>Plans and Associated Documentation</Heading.H2>
        <Copy>
          Where plans are not yet available, a detailed preliminary sketch of the site, floor plan and elevations are
          required.
        </Copy>
        <label htmlFor="fileUpload">
          <Heading.H5>Upload plans and other relevant project documentation</Heading.H5>
        </label>
        <Margin vertical={4}>
          <FileUpload
            onChange={handleFileUploadChange}
            onLoadingChanged={setIsUploading}
            setErrorMessage={setFileUploadError}
          />
        </Margin>
        {fileUploadError && <FieldError>{fileUploadError}</FieldError>}
        {errors.files && <FieldError>{((errors.files as unknown) as ReactHookFormFieldError).message}</FieldError>}
        <StyledRecaptcha ref={reCAPTCHARef} size="invisible" badge="bottomleft" sitekey={reCaptchaSiteKey} />
        <StyledPrimaryButton trackingId={'test'} disabled={formState.isSubmitting || isUploading} buttonType="submit">
          Submit Request
        </StyledPrimaryButton>

        <StyledRecaptchaDisclaimer>
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </StyledRecaptchaDisclaimer>
      </StyledSection>
    </StyledForm>
  );
};
