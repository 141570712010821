import React from 'react';
import { Margin } from 'styled-components-spacing';
import { Task as PaymentTaskProps } from '../../../services/openapi.types.generated';
import { ConfirmTask } from '../index';

type TaskListProps = {
  tasks: Array<PaymentTaskProps>;
  onCheckboxClick: (task: PaymentTaskProps) => void;
};

export const CheckList = ({ tasks, onCheckboxClick }: TaskListProps): JSX.Element => {
  return (
    <>
      {tasks.map((task: PaymentTaskProps) => {
        return (
          <Margin bottom={4} key={task.id}>
            <ConfirmTask
              longDescription={task.longDescription}
              cost={task.payment?.subTotal || '$0.00'}
              title={task.title}
              gst={task.payment?.gst}
              discount={task.payment?.discount}
              quantity={task.payment?.quantity}
              key={task.title}
              task={task}
              onButtonClick={onCheckboxClick}
            />
          </Margin>
        );
      })}
    </>
  );
};
