import styled from 'styled-components';
import { colorRed, bodyFamily } from '../../../theme.selectors';
/**
 * Error messages displayed in an input field showing the user
 * the invalid message.
 */
export const FieldError = styled.div`
  color: ${colorRed};
  font-family: ${bodyFamily};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;
