import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { Subject } from '../../../hooks/useSubject';
import { colorWhite, colorBlack } from '../../../theme.selectors';
import { PrimaryButton } from '../../Button/PrimaryButton';
import { PaymentStatus } from './payment-status';

export type PaymentFormProps = {
  onPayment: (status: PaymentStatus) => void;
  subject: Subject;
  isDisabled?: boolean;
};

export const StyledPrimaryButton = styled(PrimaryButton)`
  color: ${colorWhite};
  background-color: ${colorBlack};
  border: 2px solid ${colorWhite};
  width: 100%;
  ${breakpoint('tablet')`
      height: 48px;
      width: 225px;
  `}
`;
export const ButtonWrapper = styled(Margin)`
  text-align: right;
`;
