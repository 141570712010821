import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ReportHandler } from 'web-vitals';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
    enableDebug: true,
    loggingLevelConsole: 2,
    loggingLevelTelemetry: 2,
  },
});
appInsights.loadAppInsights();

const sendToAnalytics: ReportHandler = ({ id, name, value }) => {
  appInsights.trackMetric({ name, average: value }, { id });
};

export { reactPlugin, appInsights, sendToAnalytics };
