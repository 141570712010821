import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URI || '/',
    knownAuthorities: [process.env.REACT_APP_MSAL_AUTHORITY as string],
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};
