import React from 'react';
import styled from 'styled-components';
import { breakpoint } from 'styled-components-breakpoint';
import { Margin } from 'styled-components-spacing';
import { useHistory } from 'react-router-dom';
import { Markdown } from '../Markdown';
import { Copy } from '../Copy';
import { SecondaryButton } from '../Button/SecondaryButton';
import { Heading } from '../Heading';

export type BaseProps = {
  description: string;
  markdown: string;
  buttonLink?: string | (() => void);
  buttonText?: string;
  title?: string;
  children?: React.ReactNode;
};

const Container = styled.div`
  ${breakpoint('desktop')`
  max-width: 820px;
  `}
`;

const DescCopy = styled(Copy)`
  margin-top: 15px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  font-size: 18px;
`;
const StyledSecondHeading = styled(Heading.H2)`
  ${breakpoint('mobile')`
  font-size: 36px;
  line-height: 36px;
  `}
  ${breakpoint('tablet')`
  font-size: 48px;
  line-height: 36px;
  `}
  ${breakpoint('desktop')`
  font-size: 48px;
  line-height: 60px;
  `}
`;
export const BaseSuccess = ({
  children = null,
  title = 'Thanks!',
  description,
  markdown,
  buttonText,
  buttonLink,
}: BaseProps): JSX.Element => {
  const history = useHistory();

  return (
    <Container>
      <StyledSecondHeading>{title}</StyledSecondHeading>
      <DescCopy>{description}</DescCopy>
      {children}
      <Margin top={{ mobile: 5, tablet: 6, desktop: 7 }} bottom={{ mobile: 6, tablet: 7, desktop: 8 }}>
        {markdown && <Markdown markdownText={markdown} />}
      </Margin>
      {buttonText && (
        <StyledSecondaryButton
          trackingId={`Success-quote-home`}
          onClick={() => {
            if (typeof buttonLink === 'function') return buttonLink();
            history.push((buttonLink as string) ?? `/`);
          }}
        >
          {buttonText ?? 'View My Account'}
        </StyledSecondaryButton>
      )}
    </Container>
  );
};
