import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BaseSuccess } from '../../components';
import { WithExpertPanel } from '../../components/Layout/withExperModal.hoc';
import { WithLayout } from '../../components/Layout/withLayout.hoc';
import { WithSubject, WithSubjectProps } from '../../components/Layout/withSubject.hoc';
import { usePageTitle } from '../../context/PageTitleContext';

type PaymentSuccessProps = WithSubjectProps;
const Component: React.FC<PaymentSuccessProps> = ({ subject }) => {
  const { setTitle } = usePageTitle();
  useEffect(() => setTitle(`Fee Proposal ${subject?.applicationId ?? 'Paid'}`), [subject]);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const status = params.get('status');

  const input =
    status === 'paid'
      ? `# What happens next?
   1. We had an issue while processing your request, but you will be notified by email when your project has been initiated.`
      : `# What happens next?
  1. Approved will review your documentation and be in touch if further information is required.
  2. Once we have confirmed we have everything we need, we'll start working towards getting your project **Approved**!`;

  const description = status === 'paid' ? 'Your payment was received.' : 'Your payment was successful.';

  return <BaseSuccess title="Thanks!" description={description} markdown={input} />;
};

export const PaymentSuccess = WithSubject(WithLayout(WithExpertPanel(Component)));
