export const reCaptchaSiteKey: string = process.env.REACT_APP_RECAPTCHA_KEY || '';

export const knownPathways = [
  "I'm Unsure",
  'Complying Development Certificate',
  'Development Application',
  'Construction Certificate',
  'Exempt Development',
  'Feasibility Assessment',
  'Preliminary Planning Assessment',
] as const;

export type KnownPathway = typeof knownPathways[number];
export const knownPathwaysForDevelopmentType: KnownPathway[] = [
  "I'm Unsure",
  'Complying Development Certificate',
  'Development Application',
  'Construction Certificate',
  'Exempt Development',
  'Feasibility Assessment',
  'Preliminary Planning Assessment',
];

export const developmentTypes = ['Residential', 'Multi Residential', 'Commercial / Industrial', 'Other'] as const;
export type DevelopmentType = typeof developmentTypes[number];

export const stateOptions = [
  { value: 'ACT', label: 'Australian Capital Territory' },
  { value: 'NSW', label: 'New South Wales' },
  { value: 'NT', label: 'Northern Territory' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'SA', label: 'South Australia' },
  { value: 'TAS', label: 'Tasmania' },
  { value: 'VIC', label: 'Victoria' },
  { value: 'WA', label: 'Western Australia' },
] as const;
export type States = typeof stateOptions[number]['value'];

export const ApprovedPhoneNumber = process.env.REACT_APP_CONTACT_NUMBER;
