import { RefObject, useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const getRecaptchaToken = async (reCaptchaRef: RefObject<ReCAPTCHA>) => {
  if (!reCaptchaRef.current) {
    return null;
  }
  return reCaptchaRef.current.executeAsync();
};

export type UseRecaptcha = [RefObject<ReCAPTCHA>, () => Promise<string | null>];

/**
 * Hook which provides `ref` object for passing into a Recaptcha component, along with the execute function to call when a token is required from the Recaptcha component.
 */
export const useReCaptcha = (): UseRecaptcha => {
  const ref = useRef<ReCAPTCHA>(null);

  const execute = useCallback(() => getRecaptchaToken(ref), [ref]);

  return [ref, execute];
};
