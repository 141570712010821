import { List, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Layout } from '.';
import { PageTitleContext } from '../../context/PageTitleContext';
import Desktop1xJpg from '../../images/project-page-hero/desktop-1x.jpg';
import Desktop2xJpg from '../../images/project-page-hero/desktop-2x.jpg';
import Mobile1xJpg from '../../images/project-page-hero/mobile-1x.jpg';
import Mobile2xJpg from '../../images/project-page-hero/mobile-2x.jpg';
import Tablet1xJpg from '../../images/project-page-hero/tablet-1x.jpg';
import Tablet2xJpg from '../../images/project-page-hero/tablet-2x.jpg';
import { ProjectTitle } from '../Project/Title';

type WithLayoutProps = { loading: boolean };

export const WithLayout = <T extends WithLayoutProps>(Component: React.FC<T>): React.FC<T> => {
  const hoc: React.FC<T> = (props) => {
    const { loading } = props;
    const [title, setTitle] = useState('');

    useEffect(() => {
      if (loading) setTitle('Loading...');
    }, [loading]);

    return (
      <PageTitleContext.Provider
        value={{
          title,
          setTitle,
        }}
      >
        <Layout
          mobile={Mobile1xJpg}
          mobile2x={Mobile2xJpg}
          tablet={Tablet1xJpg}
          tablet2x={Tablet2xJpg}
          desktop={Desktop1xJpg}
          desktop2x={Desktop2xJpg}
          heading={<ProjectTitle projectName={title} />}
        >
          {loading ? (
            <Skeleton active>
              <List.Item />
            </Skeleton>
          ) : (
            <Component {...props} />
          )}
        </Layout>
      </PageTitleContext.Provider>
    );
  };

  hoc.displayName = `${Component.displayName}WithLayout`;

  return hoc;
};
