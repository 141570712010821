import { ProjectStatus } from '../services/openapi.types.generated';

export const ProjectStatusMap: Record<ProjectStatus, string> = {
  [ProjectStatus.Quote]: 'Pending Proposal',
  [ProjectStatus.Project]: 'In Progress',
  [ProjectStatus.ResponseRequired]: 'Awaiting Information',
  [ProjectStatus.Lodged]: 'Lodged',
  [ProjectStatus.Completed]: 'Completed',
  [ProjectStatus.Initiating]: 'Enquiry Received',
  [ProjectStatus.Cancelled]: 'Archived',
};
