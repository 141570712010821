import { string, number, object, SchemaOf, StringSchema, array } from 'yup';
import { ProjectFormData } from './config';
import { developmentTypes, KnownPathway, knownPathwaysForDevelopmentType, knownPathways } from '../constants';

const phoneRegExp = /^[+\d\s\(\)\-\/]{10,14}$/;

const postCodeLength = 'Post code must be four digits long';

const pathIsRequired = '${path} is required';

export const projectRequestSchema: SchemaOf<ProjectFormData> = object({
  requestId: string().required(),
  value: number()
    .optional()
    .transform((_value, originalValue) => Number(originalValue.replace(/,/g, '')))
    .typeError('Please enter an amount in dollars for estimated cost.')
    .min(0, 'Please enter a positive amount in dollars for estimated cost.')
    .label('Estimated cost of proposed works'),
  company: string().optional().label('Company'),
  email: string().email('Please enter a valid email address.').optional().label('Email'),
  firstName: string().trim().min(1).required('First name is required.').label('First name').nullable(),
  lastName: string().trim().min(1).required('Last name is required.').label('Last name').nullable(),
  phone: string()
    .optional()
    .label('Phone number')
    .nullable()
    .matches(phoneRegExp, 'Please enter a valid phone number.'),
  state: string().required('State is required.').label('State'),
  address1: string().required('Address line 1 is required.').label('Address line 1'),
  address2: string().optional().label('Address line 2'),
  suburb: string().required('Suburb is required.').label('Suburb'),
  postcode: string()
    .length(4, postCodeLength)
    .typeError('Please enter a valid post code.')
    .required('Post code is required.')
    .label('Post code'),
  owner: string().required('Owner is required.').label('Owner'),
  description: string().required('Description of works is required.').label('Description of works'),
  knownPathway: string()
    .required('If known, please select an assessment type. Otherwise select "I\'m Unsure".')
    .oneOf([...knownPathways], 'If known, please select an assessment type. Otherwise select "I\'m Unsure".')
    .nullable()
    .label('Assessment type'),
  developmentType: string()
    .nullable()
    .when('knownPathway', (knownPathway: KnownPathway, schema: StringSchema<string | null | undefined>) =>
      knownPathwaysForDevelopmentType.includes(knownPathway)
        ? schema
            .required('If known, please select a development type. Otherwise select "Other".')
            .oneOf([...developmentTypes], 'If known, please select a development type. Otherwise select "Other".')
        : schema.optional(),
    )
    .label('Development Type'),
  clientReference: string().optional().label('Reference').nullable(),
  files: array().of(string().required().nullable(false)).optional().label('File upload'),
})
  .noUnknown()
  .required() as SchemaOf<ProjectFormData>;

// TODO - not sure this is required anymore???
export const quoteRequestSchema: SchemaOf<ProjectFormData> = object({
  requestId: string().required(),
  value: number()
    .optional()
    .transform((_value, originalValue) => Number(originalValue.replace(/,/g, '')))
    .typeError('Please enter a number.')
    .positive()
    .label('Estimated value'),
  company: string().optional().label('Company'),
  email: string().email('Please enter a valid email address.').required('Email is required.').label('Email'),
  firstName: string().required('First name is required.').label('First name'),
  lastName: string().required('Last name is required.').label('Last name'),
  phone: string()
    .required('Phone number is required.')
    .label('Phone number')
    .matches(phoneRegExp, 'Please enter a valid phone number.'),
  state: string().required('State is required.').label('State'),
  address1: string().required('Address line 1 is required.').label('Address line 1'),
  address2: string().optional().label('Address line 2'),
  suburb: string().required('Suburb is required.').label('Suburb'),
  postcode: string()
    .length(4, postCodeLength)
    .typeError('Please enter a valid post code.')
    .required('Post code is required.')
    .label('Post code'),
  owner: string().required('Owner is required.').label('Owner'),
  description: string().required('Property description is required.').label('Property description'),
  knownPathway: string()
    .required('Known pathway is required.')
    .oneOf([...knownPathways], pathIsRequired)
    .nullable()
    .label('Known Pathway'),
  developmentType: string()
    .nullable()
    .when('knownPathway', (knownPathway: KnownPathway, schema: StringSchema<string | null | undefined>) =>
      knownPathwaysForDevelopmentType.includes(knownPathway)
        ? schema.required().oneOf([...developmentTypes], pathIsRequired)
        : schema.optional(),
    )
    .label('Development Type'),
  clientReference: string().optional().label('Client Reference').nullable(),
  files: array().of(string().required().nullable(false)).optional().label('File upload'),
})
  .noUnknown()
  .required() as SchemaOf<ProjectFormData>;
