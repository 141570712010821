/* eslint-disable */
import React from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

export type BaseLinkProps = {
  trackingId?: string;

  onClick?: (event: React.MouseEvent<any, any>) => void;
};

export const buildBaseLink = <T extends BaseLinkProps>(LinkComponent: React.ComponentType<Omit<T, 'trackingId'>>) => ({
  trackingId,
  ...rest
}: T): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const onClick = rest.onClick;

  const handleClick = (event: React.MouseEvent<any, any>) => {
    if (trackingId) {
      appInsights.trackEvent({ name: trackingId });
    }
    onClick && onClick(event);
  };
  return <LinkComponent {...rest} onClick={handleClick} />;
};
