import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { PaymentSection } from '../../components';
import { WithExpertPanel } from '../../components/Layout/withExperModal.hoc';
import { WithLayout } from '../../components/Layout/withLayout.hoc';
import { WithSubject, WithSubjectProps } from '../../components/Layout/withSubject.hoc';
import { getCurrentMilestone } from '../../components/Milestones/utils';
import { isTaskPaymentAllowed } from '../../components/Project/task-logic';
import { ProjectStatus } from '../../services/openapi.types.generated';

type QuotesScreenProps = WithSubjectProps;
export const Component: React.FC<QuotesScreenProps> = (props) => {
  const { subject, setSubject, loading } = props;

  if (loading || !subject) return null;

  const history = useHistory();

  const isQuoteAccepted = subject?.status === ProjectStatus.Completed;

  const milestone = getCurrentMilestone(subject?.milestones ?? []);
  const tasks = milestone?.tasks?.filter(isTaskPaymentAllowed);

  return (
    <>
      {isQuoteAccepted ? <Redirect to={`/quotes/${subject.id}/accepted`} /> : null}
      <PaymentSection
        subject={subject}
        setSubject={setSubject}
        loading={loading}
        tasks={tasks ?? []}
        onSuccessfulInvoice={(success) => {
          if (!success) {
            return history.push(`/error?message=${encodeURIComponent('Failed to accept quote and create invoice.')}`);
          } else return history.replace(`/quotes/${subject?.id}/invoiced`);
        }}
        onPayment={(status) => {
          history.replace(`/quotes/${subject?.id}/paid?status=${status}`);
        }}
      />
    </>
  );
};

export const QuoteScreen = WithSubject(WithLayout(WithExpertPanel(Component)));
