import { CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';
import { useApiRequest } from './useApiRequest';
import { useSubject } from './useSubject';

type CurrentState = 'idle' | 'fetching' | 'complete' | 'error';
type PaymentIntent = {
  clientSecret: string;
  id: string;
};
type Payment = {
  paymentIntent: PaymentIntent;
  paymentTaskIds: Array<string>;
  total: string;
};
type ReturnProps<T> = {
  status: CurrentState;
  data?: T;
  cancelSource?: CancelTokenSource;
  errorMessage?: string;
};
/**
 * A hook for calling an endpoint to get the stripe intent
 * @param {object} projectId The id of the project
 */
export const useFetchPaymentIntent = (id: string): [ReturnProps<Payment>, () => Promise<void>] => {
  const [status, setStatus] = useState<CurrentState>('idle');
  const [intent, setPaymentIntent] = useState<Payment>();
  const subjectType = useSubject();

  const [{ data, error }, postIntent] = useApiRequest<Payment>(
    {
      method: 'post',
      url: `/payment/${subjectType}/${id}/intent`,
    },
    {
      manual: true,
      authRedirect: true,
    },
  );

  const [{ data: updatedData, error: updateError }, updateIntent] = useApiRequest<Payment>(
    {
      method: 'put',
      url: `/payment/${subjectType}/${id}/intent/${intent?.paymentIntent?.id}`,
    },
    {
      manual: true,
      authRedirect: true,
    },
  );

  const errorMsg = error?.message ?? updateError?.message ?? undefined;

  useEffect(() => setPaymentIntent(updatedData), [updatedData]);
  useEffect(() => setPaymentIntent(data), [data]);

  const fetchData = async (): Promise<void> => {
    setStatus('fetching');
    try {
      if (intent) {
        await updateIntent();
      } else {
        await postIntent();
      }
      setStatus('complete');
    } catch (e) {
      setStatus('error');
    }
  };

  return [{ status, data: intent, errorMessage: errorMsg }, fetchData];
};
