/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Address {
  /**
   * The unique address identifier.
   * @format uuid
   * @example d290f1ee-6c54-4b01-90e6-d701748f0851
   */
  id?: string;

  /**
   * @format number
   * @example 252082
   */
  planningPortalId?: string;

  /**
   * @format number
   * @example 1400559
   */
  gurasId?: string;

  /**
   * First line of the non-locality/state/postcode portion of the address
   * @example Unit 406
   */
  addressLine1: string;

  /**
   * Second line of the non-locality/state/postcode portion of the address. This will be null for addresses without a unit/level/etc number
   * @example 123 Smith Street
   */
  addressLine2?: string | null;

  /**
   * The locality/suburb of this address.
   * @example Newcastle
   */
  suburb: string;

  /**
   * The Australian state or territory where the address is located.
   * @example NSW
   */
  state: 'NSW' | 'SA' | 'WA' | 'VIC' | 'NT' | 'TAS' | 'QLD' | 'ACT' | null;

  /**
   * Holds the four digit postcode.
   * @example 2040
   */
  postcode: string;

  /**
   * Longitude coordinate (in WGS84 format).
   * @example 144.999530
   */
  longitude?: string;

  /**
   * Latitude coordinate (in WGS84 format).
   * @example -37.899031
   */
  latitude?: string;

  /**
   * Full address string
   * @example 13 Neville Street, SYDNEY NSW 2112
   */
  fullAddress?: string;

  /**
   * true if the user entered the address manually instead of using the lookup
   * @example true
   */
  manualEntry?: boolean;
}

export interface Milestone {
  /**
   * A unique identifier.
   * @format uuid
   * @example d290f1ee-6c54-4b01-90e6-d701748f0851
   */
  id: string;
  title: string;
  description?: string;
  customName?: string;
  status: 'unknown' | 'pending' | 'in-progress' | 'complete' | 'abandoned';

  /** @format date */
  estimatedCompletionDate?: string;
  children?: Milestone[];
  tasks?: Task[];
}

export interface Task {
  /**
   * A unique identifier.
   * @format uuid
   * @example d290f1ee-6c54-4b01-90e6-d701748f0851
   */
  id: string;
  milestoneId: string;
  title: string;
  shortDescription: string;
  longDescription?: string;

  /** If true, allows the user to change the assignee of the task */
  userCanAssignTask: boolean;

  /** Who the task is assigned to and is responsible for completing */
  assignedTo: 'user' | 'approved';

  /** If this task is assigned to Approved and has not been paid for, show as a pending payment task */
  requiresPaymentIfAssignedToApproved: boolean;
  status: 'NOT_REQUIRED' | 'PENDING' | 'ACTIVE' | 'RFI' | 'REVIEW' | 'COMPLETE' | 'CANCELLED';

  /** Flag to specify if the task has been delayed */
  isDelayed?: boolean;

  /** Warning or error message to display to the user */
  message?: string;
  messageType?: 'warning' | 'error';

  /**
   * If assigned to Approved, the estimated date it will be completed. If assigned to the user, the date it is due by
   * @format date
   */
  estimatedDueDate?: string;

  /** @format date */
  completedDate?: string;
  files?: UploadedFile[];
  payment?: TaskPayment;
}

export interface TaskPayment {
  discount: string;
  quantity: string;
  gst: string;
  status: 'unpaid' | 'pending' | 'invoiced' | 'paid';
  subTotal: string;
}

export interface UploadedFile {
  id: string;
  name?: string;

  /** @format date-time */
  uploadedDate: string;
  size?: number;
  mimetype?: string;
  url: string;
}

export interface AcceptQuoteRequest {
  approvedUserId: string;
  approvedUserEmail: string;
  validFrom: string;
}

export interface QuoteRequest {
  /**
   * A unique identifier for the quote.
   * @format uuid
   * @example d290f1ee-6c54-4b01-90e6-d701748f0851
   */
  id?: string;

  /**
   * A token provided by v2 of Google reCAPTCHA which will be verified
   * @example 03AGdBq26Mq2zaqD
   */
  recaptcha: string | null;

  /**
   * The users first name.
   * @example Jim
   */
  firstName: string;

  /**
   * The users last name.
   * @example Johns
   */
  lastName: string;

  /**
   * The users company name.
   * @example ABC Tech
   */
  company?: string;

  /**
   * The users phone number.
   * @example 445438543
   */
  phone: string;

  /**
   * The users email address.
   * @example jim.johns@abctech.com
   */
  email: string;
  address: Address;

  /**
   * Does the user own this work?
   * @example true
   */
  owner?: boolean;

  /**
   * A brief description of the proposed work.
   * @example Building a pool
   */
  description: string;

  /**
   * The cost of the proposed work
   * @example 2000
   */
  price?: number;

  /**
   * The pathway that the user thinks it will be.
   * @example Complying Development Certificate
   */
  knownPathway:
    | "I'm Unsure"
    | 'Complying Development Certificate'
    | 'Development Application'
    | 'Construction Certificate'
    | 'Exempt Development'
    | 'Feasibility Assessment'
    | 'Preliminary Planning Assessment';

  /**
   * The development type that the user thinks it will be.
   * @example Residential
   */
  developmentType?: 'Residential' | 'Multi Residential' | 'Commercial / Industrial' | 'Other' | null;

  clientReference: string | null;

  /** An array of urls to any files that were uploaded. */
  files?: string[] | null;
  userId?: string;
}

export interface NewProjectRequest {
  firstName: string | null;

  lastName: string | null;

  phone: string | null;

  address: Address;

  /**
   * Does the user own this work?
   * @example true
   */
  owner?: boolean;

  /**
   * A brief description of the proposed work.
   * @example Building a pool
   */
  description: string;

  /**
   * The cost of the proposed work
   * @example 2000
   */
  price?: number;

  /**
   * The pathway that the user thinks it will be.
   * @example Complying Development Certificate
   */
  knownPathway:
    | "I'm Unsure"
    | 'Complying Development Certificate'
    | 'Development Application'
    | 'Construction Certificate'
    | 'Exempt Development'
    | 'Feasibility Assessment'
    | 'Preliminary Planning Assessment';

  /**
   * The development type that the user thinks it will be.
   * @example Residential
   */
  developmentType?: 'Residential' | 'Multi Residential' | 'Commercial / Industrial' | 'Other' | null;

  clientReference: string | null;

  /** An array of IDs to any files that were uploaded. */
  files?: string[] | null;
  userId?: string;

  /**
   * A token provided by v2 of Google reCAPTCHA which will be verified
   * @example 03AGdBq26Mq2zaqD
   */
  recaptcha: string | null;
}

export interface Expert {
  /** @example 443e5ec6-0e09-4382-8ba4-90a30b215665 */
  id: string;

  /** @example Neo Smith */
  name: string;

  /** @example 1300 433 665 */
  phone: string;

  /** @example neo@example.com */
  email: string;
}

export interface Organisation {
  /**
   * A unique identifier.
   * @format uuid
   * @example d290f1ee-6c54-4b01-90e6-d701748f0851
   */
  _id: string;
  isInvoiced?: boolean;
  contactIds?: string[];

  /** Read-only - contacts mapped from contacts in Alvin */
  contacts?: Contact[];
}

export interface Contact {
  /**
   * A unique identifier.
   * @format uuid
   * @example d290f1ee-6c54-4b01-90e6-d701748f0851
   */
  _id: string;

  /** The user id of the approved user */
  userId?: string;
  email?: string;

  firstName?: string;
  lastName?: string;
  phone?: string;
}

export enum ProjectStatus {
  Quote = 'quote',
  Project = 'project',
  ResponseRequired = 'responseRequired',
  Lodged = 'lodged',
  Completed = 'completed',
  Initiating = 'initiating',
  Cancelled = 'cancelled',
}

export enum TaskStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  RFI = 'RFI',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
}

export enum PrincipalCertifier {
  BuildCert = 'buildCert',
  Other = 'other',
}

export interface Project {
  /**
   * A unique identifier.
   * @format uuid
   * @example 0425ad65-3c7a-454e-8155-4ae40d6c49b5
   */
  id: string;

  /**
   * Reference number used by a company to link with their internal id for a project
   * @example 12345
   */
  referenceId?: string;
  applicationId: string;

  /** @format iso date string */
  createDate?: string;

  /** @format iso date string */
  updateDate?: string;

  /** @format iso date string */
  estimatedCompletionDate: string;
  status: ProjectStatus;

  /**
   * Id of the primary contact organisation
   * @format uuid
   */
  organisationId?: string;

  /**
   * Id of the user who created this project
   * @format uuid
   */
  userId?: string;
  address?: Address;
  pathway: Pathway;
  principleCertifier?: PrincipalCertifier;
  assignedExpert?: Expert;
  milestones: Milestone[];

  /** Id of the active quote on a project, should it exist */
  activeQuote?: string;

  /**
   * A markdown string telling the customer the next steps on how to proceed
   * @format markdown
   */
  nextSteps?: string;

  /** Read-only - calculated when returning from the server */
  isInvoiced?: boolean;

  /** Read-only - filled when returning from the server based on the userId */
  user?: any;

  /** Organisation of the primary contact for the project */
  organisation?: Organisation;

  /** Read-only - contacts mapped from contacts in Alvin */
  contacts?: Contact[];

  /** Read-only - automatically calculated from the current in-progress milestone */
  currentMilestone?: string;

  /** Formatted total price for all payment items on the quote */
  totalPrice: string;

  /** Formatted subtotal price for all payment items on the quote */
  subTotalPrice: string;

  /** Formatted total gst for all payment items on the quote */
  totalGST: string;

  /** When true, allows the project to be lodged */
  readyForLodging?: boolean;

  /**
   * Date the download project link should expire. Should be an ISO date/time string e.g. 2021-02-25T22:23:00.000Z
   * @format iso-date-time
   */
  downloadExpirationDate?: string;

  /**
   * Valid from date to maintain concurrency
   * @format iso-date-time
   */
  validFrom?: string;
}

export interface GetManyProject {
  /**
   * A unique identifier.
   * @format uuid
   * @example 0425ad65-3c7a-454e-8155-4ae40d6c49b5
   */
  id: string;

  /**
   * Reference number used by a company to link with their internal id for a project
   * @example 12345
   */
  referenceId?: string;
  applicationId: string;
  activeQuote?: string;

  /** @format iso date string */
  estimatedCompletionDate: string;
  status: ProjectStatus;
  address?: Address;
  pathway: Pathway;

  /** Read-only - automatically calculated from the current in-progress milestone */
  currentMilestone?: string;
}

export interface DocumentRequest {
  name: string;
  size: number;
  type: string;

  /** A token provided by v2 of Google reCAPTCHA which will be verified */
  recaptcha: string | null;
}

export interface DocumentResponse {
  id: string;
  url: string;
  uploadToken: string;
}

export interface Quote {
  /**
   * A unique identifier.
   * @format uuid
   * @example 0425ad65-3c7a-454e-8155-4ae40d6c49b5
   */
  id: string;

  /**
   * Reference number used by a company to link with their internal id for a project
   * @example 12345
   */
  referenceId?: string;
  applicationId: string;
  projectId?: string;

  /** @format iso date string */
  createDate?: string;

  /** @format iso date string */
  updateDate?: string;

  /** @format iso date string */
  estimatedCompletionDate: string;
  status: ProjectStatus;

  /**
   * Id of the primary contact organisation
   * @format uuid
   */
  organisationId?: string;

  /**
   * Id of the user who created this project
   * @format uuid
   */
  userId?: string;
  address?: Address;
  pathway: Pathway;
  principleCertifier?: PrincipalCertifier;
  assignedExpert?: Expert;
  milestones: Milestone[];

  /**
   * A markdown string telling the customer the next steps on how to proceed
   * @format markdown
   */
  nextSteps?: string;

  /** Read-only - calculated when returning from the server */
  isInvoiced?: boolean;

  /** Read-only - filled when returning from the server based on the userId */
  user?: any;

  /** Read-only - organisation with accompanying contacts */
  organisation?: Organisation;

  /** Read-only - contacts mapped from contacts in Alvin */
  contacts?: Contact[];

  /** Read-only - automatically calculated from the current in-progress milestone */
  currentMilestone?: string;

  /** Formatted total price for all payment items on the quote */
  totalPrice: string;

  /** Formatted subtotal price for all payment items on the quote */
  subTotalPrice: string;

  /** Formatted total gst for all payment items on the quote */
  totalGST: string;

  /** When true, allows the project to be lodged */
  readyForLodging?: boolean;

  /**
   * Date the download project link should expire. Should be an ISO date/time string e.g. 2021-02-25T22:23:00.000Z
   * @format iso-date-time
   */
  downloadExpirationDate?: string;

  /**
   * Valid from date to maintain concurrency
   * @format iso-date-time
   */
  validFrom: string;
}

export interface Pathway {
  /** @example DA Required */
  name: string;

  /** @example Your development requires a Development Application. */
  description?: string;

  /** @example Proposed development does not comply with required rear setback */
  reason?: string;
}

export type AssignTasks = { id: string; assignedTo: 'user' | 'approved' }[];

export type AssignTasksResults = {
  id: string;
  wasUpdated: boolean;
  failureReason?: 'denied' | 'not-found';
}[];
